import React from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { contact } from "../../API/API";

export default function Contact() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  // CONTACT
  const [contactData, setContactData] = React.useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleContact = async () => {
  //   if (
  //     contactData.email.length == "" ||
  //     contactData.name.length == "" ||
  //     contactData.contact.length == "" ||
  //     contactData.message.length == ""
  //   ) {
  //     alert("All Field Required");
  //     return;
  //   }
  //   var json = await contact(contactData);
  //   if (json.status) {
  //     alert("Submit Successfully");
  //     window.location.reload();
  //   } else {
  //     alert(json.message);
  //   }
  // };

  const handleContact = () => {
    if (
      !contactData.email ||
      !contactData.name ||
      !contactData.contact ||
      !contactData.message
    ) {
      alert("All Field Required");
      return;
    }

    contact(contactData).then((json) => {
      if (json.status) {
        alert("Submit Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("We are here to help")}
          </Paragraph>
          {/* <Paragraph color="#FFF" size="14px" margin="0 0 1rem 0" center>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque
            penatibus et
          </Paragraph> */}
        </Layout>
      </div>
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#707070"
                  size="28px"
                  bold="700"
                  margin="0 0 1rem 0"
                >
                  {t(
                    "We'd love to hear from you! Send us a message or email us."
                  )}
                </Paragraph>

                <br />
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.theCircle}>
                    <Phone sx={{ color: "#333" }} />
                  </div>
                  <Paragraph color="#000">
                    <span style={{ fontWeight: "bold", marginBottom: ".4rem", display: "block" }}>
                      {t("Malaysia HQ Office")}
                    </span>
                    {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                     
                    </Box> */}
                    <a
                      style={{
                        color: "#000",
                        display: "block",
                        // marginRight: "10px",
                        marginBottom: ".4rem"
                      }}
                      href="tel:+6588288815"
                    >
                      {/* <br /> */}
                      +65 8828 8815
                    </a>


                  </Paragraph>
                </div>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.theCircle}>
                    <Mail sx={{ color: "#333" }} />
                  </div>
                  <Paragraph color="#000">
                    <a
                      style={{
                        color: "#000",
                      }}
                      href="mailto:wehealthymanagement@gmail.com"
                    >
                      wehealthymanagement@gmail.com
                    </a>
                  </Paragraph>
                </div>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.theCircle}>
                    <Room sx={{ color: "#333" }} />
                  </div>
                  <Paragraph color="#000">
                    <a
                      href="https://goo.gl/maps/NtXGND55PWEPugJHA"
                      style={{ color: "#000" }}
                    >
                      APT/BLK 3 Beach Road <br />
                      #01-4835 <br />
                      Singapore 190003
                    </a>
                  </Paragraph>
                </div>
                <br />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className={classes.theCircle_}>
                    <Facebook sx={{ color: "#333" }} />
                  </div>
                  <Box sx={{ width: { xs: "80%", md: "100%" } }}>
                    <Paragraph color="#000" style={{ wordWrap: "break-word" }}>
                      <a
                        href="https://www.facebook.com/RoyalBitterGourdPeptide/"
                        style={{
                          color: "#000",
                        }}
                      >
                        https://www.facebook.com/RoyalBitterGourdPeptide/
                      </a>
                    </Paragraph>
                  </Box>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <FormInput
                  label={t("Name")}
                  placeholder={t("Name")}
                  name="name"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Email")}
                  placeholder={t("Email")}
                  name="email"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Contact Number")}
                  placeholder={t("Contact Number")}
                  // type="number"
                  name="contact"
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Message")}
                  placeholder={t("Message")}
                  rows="3"
                  multiline
                  name="message"
                  onChange={handleChange}
                />
                <br />
                <Button
                  color="#FFF"
                  bg="#4FCEA1"
                  label={t("SEND")}
                  size="14px"
                  br="4px"
                  pd=".6rem 1rem"
                  onClick={() => handleContact()}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
