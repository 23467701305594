import React, { useEffect } from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { edit_profile, get_profile } from "../../API/API";

export default function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // GET PROFILE
  // const [token, setToken] = React.useState([]);

  // React.useEffect(() => {
  //   let token = window.localStorage.getItem("token");
  //   setToken(token || "");
  //   if (!token || "") {
  //     navigate("/login");
  //   }
  // }, []);
  const { getToken, getUser } = useAuth();

  const [profileData, setProfileData] = React.useState({
    email: "",
    name: "",
    dob: "",
    contact: "",
  });

  const getProfileData = async () => {

    var res = await get_profile();
    if (res.status) {
      setProfileData(res.data);
      setForm({
        ...res.data
      })
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };


  useEffect(() => {
    getProfileData();
  }, []);
  // EDIT PROFILE

  const [form, setForm] = React.useState({});
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditProfile = async () => {


    var res = await edit_profile(form);
    if (res.status) {
      alert("Saved");
      getProfileData();
    }
    // edit_profile(profileData).then((json) => {
    //   if (json.status) {
    //     alert("Submit Successfully");
    //     window.location.reload();
    //   } else {
    //     alert(json.message);
    //   }
    // });
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a
                  className={clsx(classes.theNavTab)}
                  href="/dashboard"
                >
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab, classes.theActive)} href="/profile">
                  {t("My Profile")}
                </a>
                {profileData && (
                  <>
                    {
                      profileData.is_confirmed_member == 1 && (
                        <a className={clsx(classes.theNavTab)} href="/membership">
                          {t("My Membership")}
                        </a>
                      )
                    }
                  </>
                )}


                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>

                {profileData && (
                  <>
                    {profileData.is_confirmed_member == 1 && (
                      <a className={clsx(classes.theNavTab)} href="/referral">
                        {t("My Referral")}
                      </a>
                    )}

                  </>
                )}
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Paragraph
                color="#194234"
                size="28px"
                bold="700"
                margin="0 0 1rem 0"
              >
                
              </Paragraph>
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("YOUR PROFILE")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <FormInput
                    label={t("Name")}
                    placeholder={t("Name")}
                    value={form.name}
                    name="name"
                    onChange={handleChange}
                  />
                  <FormInput
                    label={t("Email Address")}
                    // name="email"
                    value={form.email}
                    disabled={true}
                    placeholder={t("Email Address")}
                  />
                  <FormInput
                    label={t("Contact Number")}
                    // type="number"
                    value={form.contact}
                    name="contact"
                    placeholder={t("Contact Number")}
                    onChange={handleChange}
                  />
                  <FormInput
                    label={t("Date of Birth")}
                    type="date"
                    value={form.DOB}
                    placeholder={t("Date of Birth")}
                    name="DOB"
                    onChange={handleChange}
                  />
                  <br />
                  <div className={classes.theBtnSizing}>
                    <Button
                      color="#FFF"
                      bg="#4FCEA1"
                      label={t("SAVE CHANGES")}
                      size="14px"
                      br="4px"
                      pd=".6rem 1rem"
                      onClick={() => handleEditProfile()}
                    />
                  </div>
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
