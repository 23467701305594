import React from "react";
import classNames from "classnames";
import { ButtonBox, Label } from "./styles";

export default function Button({
  bg,
  br,
  bd,
  pd,
  hover,
  margin,
  style,
  label,
  icon,
  color,
  size,
  bold,
  className,
  children,
  ...others
}) {
  return (
    <ButtonBox
      bg={bg}
      br={br}
      bd={bd}
      pd={pd}
      hover={hover}
      margin={margin}
      style={style}
      className={classNames(className)}
      {...others}
    >
      {icon}
      <Label color={color} size={size} bold={bold}>
        {label}
      </Label>
    </ButtonBox>
  );
}
