import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import home005 from "../../assets/images/home005.png";

const config = {
  theNavList: {
    position: "relative",
    // width: "100%"
  },
  theNavTab: {
    position: "relative",
    background: "#FFF",
    color: "#194234",
    padding: "1rem 2rem",
    display: "block",
  },
  theActive: {
    background: "#4FCEA1",
    color: "#FFF",
  },
  theHead: {
    background: "#194234",
    padding: ".6rem 1.5rem",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  theBody: {
    padding: "1.5rem",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  theBtnSizing: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...mq("md", {
      width: "30%",
    }),
  },
  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  theIMG2: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  theTabActive: {
    background: "#194234",
    color: "#FFF",
    padding: ".6rem 1.5rem",
    borderRadius: "4px",
    marginRight: "1rem",
  },
  theTab: {
    background: "#8BA099",
    color: "#FFF",
    padding: ".6rem 1.5rem",
    borderRadius: "4px",
    marginRight: "1rem",
  },
  theList: {
    position: "relative",
    border: "1px solid #d7d7d7",
    padding: "1rem",
    marginBottom: "1rem",
    borderRadius: "6px",
  },
  theCrown: {
    position: "relative",
    width: "35px",
    "& img": {
      width: "100%"
    }
  }
};

export default makeStyles(config);
