import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { change_password, get_profile } from "../../API/API";

export default function Profile() {
  const classes = useStyles();
  const navigate = useNavigate();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  const { t } = useTranslation();
  // PASSWORD

  const [token, setToken] = React.useState([]);
  const [profileData, setProfileData] = React.useState(null);
  const getProfileData = async () => {

    var res = await get_profile();
    if (res.status) {
      setProfileData(res.data);
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };
  React.useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    // if (!token || "") {
    //   navigate("/login");
    // }
    getProfileData();
  }, []);

  const [passwordData, setPasswordData] = React.useState({
    password: "",
    password2: "",
  });

  const handleChange = (e) => {
    setPasswordData({
      ...passwordData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSavePassword = async () => {
    if (
      passwordData.password.length == "" ||
      passwordData.password2.length == ""
    ) {
      alert("All Field Required");
    } else {
      var res = await change_password(passwordData);
      if (res.status) {
        alert("Saved");
      }
    }
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a
                  className={clsx(classes.theNavTab)}
                  href="/dashboard"
                >
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                {profileData && (
                  <>
                    {
                      profileData.is_confirmed_member == 1 && (
                        <a className={clsx(classes.theNavTab)} href="/membership">
                          {t("My Membership")}
                        </a>
                      )
                    }
                  </>
                )}


                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>

                {profileData && (
                  <>
                    {profileData.is_confirmed_member == 1 && (
                      <a className={clsx(classes.theNavTab)} href="/referral">
                        {t("My Referral")}
                      </a>
                    )}

                  </>
                )}
                <a className={clsx(classes.theNavTab, classes.theActive)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>

              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("SETTING")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <FormInput
                    label={t("New Password")}
                    value={passwordData.password}
                    name="password"
                    type="password"
                    placeholder={t("New Password")}
                    onChange={handleChange}
                  />
                  <FormInput
                    label={t("Re-enter Password")}
                    value={passwordData.password2}
                    name="password2"
                    type="password"
                    placeholder={t("Re-enter Password")}
                    onChange={handleChange}
                  />
                  <br />
                  <div className={classes.theBtnSizing}>
                    <Button
                      color="#FFF"
                      bg="#4FCEA1"
                      label={t("SAVE CHANGES")}
                      size="14px"
                      br="4px"
                      pd=".6rem 1rem"
                      onClick={() => handleSavePassword()}
                    />
                  </div>
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
