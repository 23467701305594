import React from "react";
import useStyles from "./styles";
import { Box, Dialog, DialogContent } from "@mui/material";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { forgot_password, login } from "../../API/API";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  // OPEN MODAL
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // LOGIN
  const [loginData, setLoginData] = React.useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
    setEmailData({
      ...emailData,
      [e.target.name]: e.target.value,
    });
  };
  const handleLogin = async () => {
    if (loginData.email.length == "" || loginData.password.length == "") {
      alert("All Field Required");
      return;
    }
    var json = await login(loginData);
    if (json.status) {
      window.localStorage.setItem("token", json.data);
      alert("Welcome Back");
      navigate("/");
    } else {
      alert(json.message);
    }
    // login(loginData).then((json) => {
    //   if (json.status) {
    //     window.localStorage.setItem("token", json.data.token);
    //     alert("Welcome Back");
    //     navigate("/");
    //   } else {
    //     alert(json.message);
    //   }
    // });
  };

  const [emailData, setEmailData] = React.useState({
    email: "",
  });

  const handleForgotPassword = async () => {
    if (emailData.email.length == "") {
      alert("All Field Required");
      return;
    }
    var res = await forgot_password({
      email: emailData.email,
    });

    if (res.status) {
      alert("An email has been sent to you");
    }
    // if (!passwordData.email) {
    //   alert("All Field Required");
    // } else {
    //   forgot_password({
    //     ...passwordData,
    //   }).then((json) => {
    //     if (json.status) {
    //       alert("Please check your email");
    //     } else {
    //       alert(json.message);
    //     }
    //   });
    // }
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <div className={classes.theBG}>
        <Layout>
          <Card bg="#FFF" pd="2rem 1rem" className={classes.theBox}>
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "80%",
                },
                margin: "0 auto",
              }}
            >
              <Paragraph
                color="#194234"
                size="24px"
                bold="700"
                margin="0 0 2rem 0"
                center
              >
                {t("LOGIN")}
              </Paragraph>
              <FormInputNoLabel
                placeholder={t("Email")}
                name="email"
                onChange={handleChange}
              />
              <FormInputNoLabel
                placeholder={t("Password")}
                type="password"
                name="password"
                onChange={handleChange}
              />
              <a
                style={{
                  color: "#707070",
                  fontSize: "14px",
                  fontStyle: "italic ",
                  display: "block",
                }}
                onClick={() => handleOpenModal()}
              >
                {t("Forget Password")}
              </a>
              <br />
              <div className={classes.theBtnSizing}>
                <Button
                  color="#FFF"
                  bg="#4FCEA1"
                  label={t("LOGIN")}
                  size="14px"
                  br="4px"
                  pd=".6rem 1rem"
                  onClick={() => handleLogin()}
                />
              </div>
            </Box>
          </Card>
        </Layout>
      </div>
      {/* <Footer /> */}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Close
                sx={{ color: "#353535", cursor: "pointer" }}
                onClick={() => setOpenModal(false)}
              />
            </div>

            <br />
            <Paragraph
              center
              size="24px"
              bold="700"
              color="#000"
              margin="0 0 1rem 0"
            >
              {t("RESET PASSWORD")}
            </Paragraph>
            <FormInput
              label={t("Email Address")}
              placeholder={t("Email Address")}
              // textLabelColor="#878787"
              bold="500"
              border="1px solid #d7d7d7 "
              // type="password"
              name="email"
              onChange={handleChange}
            />
            <div className={classes.theBtnSizing}>
              <Button
                label={t("SUBMIT")}
                color="#FFF"
                bg="#4FCEA1"
                br="6px"
                onClick={() => handleForgotPassword()}
              />
            </div>

            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
