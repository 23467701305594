import React, { useEffect, useState } from "react";
import useStyles, {
  FooterContainer,
  NavbarLogo,
  NavbarLink,
  EmailInput,
} from "./styles";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Box, Container, Dialog, DialogContent, Grid } from "@mui/material";
import Layout from "../Layout/Layout";
import Paragraph from "../Typography/Paragraph";
// import FormInputNoLabel from "../FormInputNoLabel/FormInputNoLabel";
import {
  Mail,
  Phone,
  Storefront,
  Facebook,
  Instagram,
  Twitter,
  Room,
  ChatBubble,
  Telegram,
  Campaign,
  WhatsApp,
} from "@mui/icons-material";

import logo from "../../assets/images/logo.svg";
import FormInputNoLabel from "../TextField/FormInputNoLabel/FormInputNoLabel";
import Button from "../Button/Button";
import { useTranslation } from "react-i18next";
import wechat from "../../assets/images/wechat.png";
import wechatqrcode from "../../assets/images/wechatqrcode.png";
import payment from "../../assets/images/payment2.png";
import facebook from "../../assets/images/facebook.png";
import instagram from "../../assets/images/instagram.png";
import whatapps from "../../assets/images/whatapps.png";
import tiktok from "../../assets/images/tiktok.png";
import youtube from "../../assets/images/youtube.png";

export default function Footer() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // OPEN MODAL
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenAddModal = () => {
    setOpenModal(true);
  };

  return (
    <React.Fragment>
      {/* <div className={classes.theBG}>
        <Layout pd="2rem 0">
          <Box sx={{ width: { xs: "100%", md: "70%" }, margin: "0 auto" }}>
            <Paragraph color="#FFF" size="24px" margin="0 0 1rem 0" center>
              Subscribe To Get Update
            </Paragraph>
            <Paragraph color="#FFF" size="14px" margin="0 0 1rem 0" center>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
              commodo ligula eget dolor. Aenean massa. Cum sociis natoque
              penatibus et magnis dis parturient montes, nascetur ridiculus mus.
              Donec quam felis, ultricies nec, pellentesque eu, pretium
            </Paragraph>
            <br />
            <FormInputNoLabel
              placeholder="Your Email Address"
              bg="#FFF"
              br="4px"
              pd="14.5px 14px"
              InputProps={{
                endAdornment: (
                  <Button
                    color="#FFF"
                    bg="#4FCEA1"
                    label="SUBSCRIBE"
                    size="14px"
                    br="4px"
                    pd=".4rem 3rem"
                  />
                ),
              }}
            />
          </Box>
        </Layout>
      </div> */}
      <Box className={classes.thePattern}>
        <Layout pd="1rem 0">
          <div className={classes.theBox}>
            <div className={classes.theFooterLogo}>
              <img src={logo} />
            </div>
            <Paragraph color="#FFF" size="14px" center></Paragraph>
          </div>
        </Layout>
      </Box>
      <FooterContainer>
        <Container>
          <Layout pd="0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3} md={3}>
                <Paragraph margin="0 0 1rem 0" color="#194234" bold="700">
                  {t("Customer Care")}
                </Paragraph>
                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="/contact"
                >
                  {t("Contact Us")}
                </a>
                {/* <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="../../../doc/shipping.pdf"
                  target="_blank"
                >
                  {t("Delivery & Returns")}
                </a> */}
                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="../../../doc/wehealthy_terms.pdf"
                  target="_blank"
                >
                  {t("Term & Conditions")}
                </a>
                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="../../../doc/wehealthy_pdpa.pdf"
                  target="_blank"
                >
                  {t("Privacy Policy")}
                </a>
                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="../../../doc/shipppingpolicy.pdf"
                  target="_blank"
                >
                  {t("Shipping Policy")}
                </a>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Paragraph margin="0 0 1rem 0" color="#194234" bold="700">
                  {t("Contact")}
                </Paragraph>
                <span
                  style={{
                    fontWeight: "bold",
                    display: "block",
                    marginBottom: ".6rem",
                    color: "#707070",
                  }}
                >
                  {t("Malaysia HQ Office")}
                </span>
                <Box>
                  <a
                    style={{
                      color: "#707070",
                      display: "block",
                      marginBottom: ".4rem",
                      fontSize: "16px",
                      fontWeight: "400",
                      // marginRight: "10px"
                    }}
                    href="tel:+6588288815"
                  >
                    +65 8828 8815
                  </a>

                  {/* <a
                    style={{
                      color: "#707070",
                      display: "block",
                      marginBottom: ".4rem",
                      fontSize: "16px",
                      fontWeight: "400",
                      // marginLeft: "10px"
                    }}
                    href="tel:+601116169173"
                  >
                    +601116169173
                  </a> */}
                </Box>
                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                    wordWrap: "break-word",
                  }}
                  href="mailto:wehealthymanagement@gmail.com"
                >
                  wehealthymanagement@gmail.com
                </a>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Paragraph margin="0 0 1rem 0" color="#194234" bold="700">
                  {t("Our Location")}
                </Paragraph>

                <a
                  style={{
                    color: "#707070",
                    display: "block",
                    marginBottom: ".4rem",
                    fontSize: "16px",
                    fontWeight: "400",
                  }}
                  href="https://goo.gl/maps/NtXGND55PWEPugJHA"
                >
                  We Healthy Management Sdn. Bhd.
                  <br />
                  APT/BLK 3 Beach Road <br />
                  #01-4835 <br />
                  Singapore 190003
                </a>
              </Grid>

              <Grid item xs={12} sm={3} md={3}>
                <Box>
                  <div>
                    <Paragraph margin="0 0 1rem 0" color="#194234" bold="700">
                      Social Media
                    </Paragraph>
                    <div style={{ display: "flex" }}>
                      <a
                        href="https://www.facebook.com/RoyalBitterGourdPeptide/"
                        target="_blank"
                      >
                        {/* <Facebook style={{ color: "#194234" }} /> */}
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={facebook}
                        />
                      </a>
                      <a
                        href="https://instagram.com/royalpeptide?igshid=YmMyMTA2M2Y="
                        target="_blank"
                      >
                        {/* <Instagram
                          style={{ color: "#194234", margin: "0 0 0 1rem" }}
                        /> */}
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={instagram}
                        />
                      </a>
                      <a href="https://wa.me/601116169173" target="_blank">
                        {/* <WhatsApp
                          style={{ color: "#194234", margin: "0 1rem 0 1rem" }}
                        /> */}
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={whatapps}
                        />
                      </a>
                      <a onClick={() => handleOpenAddModal()}>
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={wechat}
                        />
                      </a>
                      <a href="https://www.tiktok.com/@royalbittergourdpeptide" target="_blank">
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={tiktok}
                        />
                      </a>
                      <a href="https://www.youtube.com/@RoyalBitterGourdPeptide" target="_blank">
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            marginRight: "12px",
                          }}
                          src={youtube}
                        />
                      </a>
                    </div>
                    <br />
                    <br />
                  </div>

                </Box>
              </Grid>
            </Grid>
          </Layout>
        </Container>
      </FooterContainer>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        // scroll="body"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogContent sx={{ background: "#103a30" }}>
          <Box>
            <img style={{ width: "100%" }} src={wechatqrcode} />
          </Box>
        </DialogContent>
      </Dialog>
      <div className="" style={{
        background: "#f1f1f1"
      }}>
        <Container>
          <Layout pd="0" >
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 10,
              paddingBottom: 10,
              flexWrap: "wrap"
            }}>
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "scale-down",
                  margin: "0px 6px"
                }} src="./pg1.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "scale-down",
                  margin: "0px 6px"
                }} src="./pg2.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "contain",
                  margin: "0px 6px"
                }} src="./pg3.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "cover",
                }} src="./pg4.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "contain",
                }} src="./pg5.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "cover",
                }} src="./pg6.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "cover",
                }} src="./pg7.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "cover",
                }} src="./pg8.png" />
              <img
                onClick={() => {
                  navigate("/product")
                }}
                style={{
                  width: 100,
                  height: 30,
                  objectFit: "cover",
                }} src="./pg9.png" />
            </div>
          </Layout>
        </Container>
      </div>
      <div className={classes.theSubFooter}>
        <Container>
          <Layout pd="0">
            <Paragraph bold="600" size="16px" center color="#FFF">
              © 2023 All Copy Rights Reserved
            </Paragraph>
          </Layout>
        </Container>
      </div>
    </React.Fragment>
  );
}
