import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Close, Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import reward001 from "../../assets/images/reward001.png";
import { useTranslation } from "react-i18next";
import {
  get_member_voucher,
  get_profile,
  get_vouchers,
  submit_withdrawal,
} from "../../API/API";
import crown001 from "../../assets/images/crown001.png";

export default function Membership() {
  const classes = useStyles();
  const navigate = useNavigate();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // MEMBERSHIP
  const [profileData, setProfileData] = React.useState({
    plan: {}
  });
  const [memberID, setMemberID] = React.useState({});

  const getProfileData = async () => {
    var res = await get_profile();
    if (res.status) {

      setProfileData({
        ...res.data,
        plan: {
          en: res.data.plan,
          cn: res.data.plan_cn,
          my: res.data.plan_bm,
          inr: res.data.plan_inr
        }
      });
      setMemberID(res.data.member_id);
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };

  // VOUCHER LIST
  const [voucherList, setVoucherList] = React.useState([]);

  const getVoucherList = async () => { };

  React.useEffect(() => {
    getProfileData();
    getVoucherList();
    get_data();
  }, [memberID]);

  const get_data = async () => {
    var res = await get_vouchers();
    if (res.status) {
      setVoucherList(res.data);
    }
  };

  const { t, i18n } = useTranslation();

  // OPEN MODAL
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const [withdrawData, setWithdrawData] = React.useState({
    bank_name: "",
    bank_acc: "",
    acc_name: "",
    amount: "",
  });

  const handleChange = (e) => {
    setWithdrawData({
      ...withdrawData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      withdrawData.bank_name.length == "" ||
      withdrawData.bank_acc.length == "" ||
      withdrawData.acc_name.length == "" ||
      withdrawData.amount.length == ""
    ) {
      alert("All Field Required");
      return;
    }
    submit_withdrawal({ ...withdrawData, member_id: memberID }).then((res) => {
      if (res.status) {
        alert("Submitted Successfully");
      } else {
        alert(res.message);
      }
    });
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a className={clsx(classes.theNavTab)} href="/dashboard">
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                <a
                  className={clsx(classes.theNavTab, classes.theActive)}
                  href="/membership"
                >
                  {t("My Membership")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>
                {/* <a className={clsx(classes.theNavTab)} href="/address">
                  {t("Address Book")}
                </a> */}
                <a className={clsx(classes.theNavTab)} href="/referral">
                  {t("My Referral")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Paragraph
                color="#194234"
                size="28px"
                bold="700"
                margin="0 0 1rem 0"
              >
                {t("Welcome")} <br /> {profileData.name}
              </Paragraph>
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("MY MEMBERSHIP")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <Paragraph color="#707070" margin="0 0 .4rem 0">
                          MEMBER NO.
                          <span
                            style={{
                              fontSize: "18px",
                              color: "#194234",
                              fontWeight: "600",
                              marginLeft: "1rem",
                            }}
                          >
                            {profileData.wh_code}
                          </span>
                        </Paragraph>
                      </div>

                      <Paragraph color="#707070" margin="0 0 .4rem 0">
                        {t("Email")}:
                        <span style={{ paddingLeft: "1rem" }}>
                          {profileData.email}
                        </span>
                      </Paragraph>
                      <Paragraph color="#707070" margin="0 0 .4rem 0">
                        {t("Point Available")}:{" "}
                        <span style={{ paddingLeft: "1rem" }}>
                          {profileData.points} {t("points")}
                        </span>
                      </Paragraph>
                      {profileData.active_plan != 7 && (
                        <>
                          {profileData.plan[i18n.language] && (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div className={classes.theCrown}>
                                <img src={crown001} />
                              </div>
                              <Paragraph
                                margin="0 0 0 14px"
                                bold="700"
                                color="#194234"
                              >
                                {profileData.plan[i18n.language]}
                              </Paragraph>
                            </Box>
                          )}
                        </>
                      )}

                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                      <Button
                        color="#FFF"
                        bg="#4FCEA1"
                        label={t("VIEW MY POINT HISTORY")}
                        size="14px"
                        br="4px"
                        pd=".6rem 1rem"
                        href="/point"
                      />
                      <br />
                      <Button
                        color="#FFF"
                        bg="#4FCEA1"
                        label={t("VIEW MY VOUCHER")}
                        size="14px"
                        br="4px"
                        pd=".6rem 1rem"
                        href="/voucher"
                      />
                      <br />
                      <Button
                        color="#FFF"
                        bg="#4FCEA1"
                        label={t("WITHDRAW")}
                        size="14px"
                        br="4px"
                        pd=".6rem 1rem"
                        onClick={() => handleOpenModal()}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Card>
              <br />
              <br />
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">
                    {t("VOUCHERS AND REWARDS")}
                  </Paragraph>
                </div>
                <div className={classes.theBody}>
                  <Grid container spacing={3}>
                    {voucherList.map((r) => (
                      <Grid item xs={12} sm={12} md={4}>
                        <Card pd="0" shadow>
                          <a href={"/voucherdetail/" + r.voucher_id}>
                            <div className={classes.theIMG}>
                              <img
                                src={r.image}
                                style={{
                                  width: "100%",
                                }}
                              />
                            </div>
                            <div style={{ padding: "1rem" }}>
                              <Paragraph
                                color="#194234"
                                bold="700"
                                margin="0 0 .4rem 0"
                              >
                                {r.name}
                              </Paragraph>
                              <Paragraph color="#707070" size="12px">
                                {t("Valid until")} {r.expiry}
                              </Paragraph>
                            </div>
                          </a>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Close
                sx={{ color: "#353535", cursor: "pointer" }}
                onClick={() => setOpenModal(false)}
              />
            </div>

            <br />
            <Paragraph
              color="#194234"
              size="24px"
              bold="700"
              margin="0 0 2rem 0"
              center
            >
              {t("WITHDRAWAL")}
            </Paragraph>

            <FormInput
              label={t("Bank Name")}
              placeholder={t("Bank Name")}
              name="bank_name"
              onChange={handleChange}
            />
            <FormInput
              label={t("Bank Account")}
              placeholder={t("Bank Account")}
              name="bank_acc"
              onChange={handleChange}
            />
            <FormInput
              label={t("Account Name")}
              placeholder={t("Account Name")}
              name="acc_name"
              onChange={handleChange}
            />
            <FormInput
              label={t("Amount")}
              placeholder={t("Amount")}
              name="amount"
              onChange={handleChange}
            />
            <br />
            <div className={classes.theBtnSizing}>
              <Button
                label={t("SUBMIT")}
                color="#FFF"
                bg="#4FCEA1"
                br="6px"
                onClick={() => handleSubmit()}
              />
            </div>
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
