// import React, { useContext } from "react";
// import { AuthContext } from "../context/AuthContext";

// const Storage = (auth) => {
//   localStorage.setItem("user", JSON.stringify(auth));
// };

// const useAuth = () => {
//   const [auth, setAuth] = useContext(AuthContext);

//   React.useEffect(() => {
//     Storage();
//   }, [auth]);

//   function getUser() {
//     return auth;
//   }

//   function setUser(auth) {
//     setAuth(auth);
//   }

//   return auth, getUser, setUser;
// };

// export default useAuth;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const Storage = (auth) => {
  try {
    localStorage.setItem("user", JSON.stringify(auth));
  } catch (error) {
    console.error("ERROR SETTING USER IN LOCALSTORAGE:", error);
  }
};

const useAuth = () => {
  const [auth, setAuth] = useContext(AuthContext);
  const [token, setToken] = React.useState([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (auth) {
      Storage(auth);
    }
  }, [auth]);

  React.useEffect(() => {
    const token = window.localStorage.getItem("token");
    // if (!token) {
    //   navigate("/login");
    // } else {
    //   setToken(token);
    // }
    setToken(token);
  }, []);

  const getToken = () => token;

  const getUser = () => auth;

  const setUser = (user) => setAuth(user);

  return { auth, getToken, getUser, setUser };
};

export default useAuth;

