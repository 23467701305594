import React, { useEffect, useState } from "react";
import { Routes as Router, Route } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import ProductPage from "./pages/Product/Product";
import AboutPage from "./pages/About/About";
import ContactPage from "./pages/Contact/Contact";
import LoginPage from "./pages/Auth/Login";
import RegisterPage from "./pages/Auth/Register";

import AddressPage from "./pages/Account/Address";
import DashboardPage from "./pages/Account/Dashboard";
import MembershipPage from "./pages/Account/Membership";
import OrderPage from "./pages/Account/Order";
import ProfilePage from "./pages/Account/Profile";
import ReferralPage from "./pages/Account/Referral";
import SettingPage from "./pages/Account/Setting";
import VoucherPage from "./pages/Account/Voucher";
import PointPage from "./pages/Account/Point";
import VoucherDetailPage from "./pages/Account/VoucherDetail";
import PaymentPage from "./pages/Payment/Payment";
import RewardPage from "./pages/Reward/Reward";
import JoinPage from "./pages/Join/Join";
import FeedbackPage from "./pages/Feedback/Feedback";
import PlanPage from "./pages/Plan/Plan";
import NewsPage from "./pages/News/News";
import NewsDetailPage from "./pages/News/NewsDetail";
import OrderHistoryDetail from "./pages/Account/OrderHistoryDetail";
import VideoPage from "./pages/Video/Video";
import VideoDetailPage from "./pages/Video/VideoDetail";
import { useTranslation } from "react-i18next";

export default function Routes() {

  const {i18n} = useTranslation();

  useEffect(()=>{
    if(i18n.language == 'id'){
      i18n.changeLanguage("inr");
    }
  },[]);
  return (
    <Router>
      <Route path="/" element={<HomePage />} />
      <Route path="/product" element={<ProductPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<PlanPage />} />
      <Route path="/about" element={<AboutPage />} />
      {/* <Route path="/address" element={<AddressPage />} /> */}
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/membership" element={<MembershipPage />} />
      <Route path="/order" element={<OrderPage />} />
      <Route path="/order/details/:order_id" element={<OrderHistoryDetail />} />
      <Route path="/profile" element={<ProfilePage />} />
      <Route path="/referral" element={<ReferralPage />} />
      <Route path="/setting" element={<SettingPage />} />
      <Route path="/voucher" element={<VoucherPage />} />
      <Route path="/point" element={<PointPage />} />
      <Route
        path="/voucherdetail/:voucher_id"
        element={<VoucherDetailPage />}
      />
      <Route path="/payment" element={<PaymentPage />} />
      <Route path="/reward" element={<RewardPage />} />
      <Route path="/join" element={<JoinPage />} />
      <Route path="/feedback" element={<FeedbackPage />} />
      <Route path="/plan" element={<PlanPage />} />
      <Route path="/news" element={<NewsPage />} />
      <Route path="/newsdetail/:news_id" element={<NewsDetailPage />} />
      <Route path="/videos" element={<VideoPage />} />
      <Route path="/videodetail/:video_id" element={<VideoDetailPage />} />
    </Router>
  );
}
