import React, { useState } from "react";
import useStyles from "./styles";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { useTranslation } from "react-i18next";
import { get_plans, register } from "../../API/API";
import { useNavigate } from "react-router-dom";
import useCart from "../../hooks/useCart";
import useAuth from "../../hooks/useAuth";

export default function Plan() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useAuth();
  const { t, i18n } = useTranslation();

  const [plans, setPlans] = React.useState([]);

  const get_data = async () => {
    var res = await get_plans();

    if (res.status) {
      var _plans = [...res.data];
      for (var i = 0; i < _plans.length; i++) {
        _plans[i]["name"] = {
          en: _plans[i].name,
          cn: _plans[i].name_cn,
          my: _plans[i].name_bm,
          inr: _plans[i].name_inr
        };
        _plans[i]["description"] = {
          en: _plans[i].description,
          cn: _plans[i].description_cn,
          my: _plans[i].description_bm,
          inr: _plans[i].description_inr
        };
      }
      setPlans(_plans);
    }
  };

  React.useEffect(() => {
    get_data();
  }, []);

  // HANDLE ADD TO CART
  const { addProduct, getCart } = useCart();

  const handleAddToCart = (plan_id) => {
    var data = [];
    var _plan = plans.find((plan_arr) => plan_arr.plan_id == plan_id);

    data = {
      name: _plan.name,
      price: _plan.item.price,
      quantity: 1,
      product_id: _plan.item.item_id,
    };

    if (token != "") {
      addProduct(data);
      navigate("/payment");
    } else {
      navigate("/login");
    }
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Paragraph
          color="#194234"
          size="28px"
          bold="700"
          margin="0 0 1rem 0"
          center
        >
          {t("Choose Your Plan")}
        </Paragraph>
        <Paragraph coor="#707070" margin="0 0 1rem 0" center>
          {t("Select one of our plans to enjoy better prices and rewards")}
        </Paragraph>
        <br />
        <Grid container spacing={3}>
          {plans.map((r) => (
            <Grid item xs={12} sm={6} md={4}>
              <Card shadow pd="2rem">
                <Paragraph
                  color="#707070"
                  size="20px"
                  bold="600"
                  margin="0 0 2rem 0"
                  center
                >
                  {r.name[i18n.language]}
                </Paragraph>
                <div
                  style={{ whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{
                    __html: r.description[i18n.language],
                  }}
                ></div>
                {/* <ul style={{ padding: "0 0 0 1.5rem", color: "#707070" }}>
                  <li style={{ collor: "#707070", marginBottom: "1rem" }}>
                    {r.quantity} {t("boxes of Royal Bitter Gourd Peptide.")}
                  </li>
                  <li style={{ collor: "#707070", marginBottom: "1rem" }}>
                    {t("free_boxes", { free_quantity: r.free_quantity })}
                  </li>
                  <li style={{ collor: "#707070", marginBottom: "1rem" }}>
                    {t("cashback_value", { points: r.points_to_buyer })}
                  </li>
                </ul> */}
                <h1
                  style={{
                    textAlign: "center",
                  }}
                >
                  MYR{r.price}
                </h1>
                <br />
                <Button
                  color="#FFF"
                  bg="#4FCEA1"
                  label={t("Choose Plan")}
                  size="14px"
                  br="4px"
                  pd=".6rem 1rem"
                  onClick={() => handleAddToCart(r.plan_id)}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Layout>
      {/* <Footer /> */}
    </React.Fragment>
  );
}
