import React, { useEffect, useState } from "react";
import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import useStyles, {
  NavbarSidebarList,
  NavbarSidebarListLogo,
  CloseButtonBox,
} from "./styles";
import { useNavigate, useLocation } from "react-router-dom";
import { Close } from "@mui/icons-material";

import logo from "../../assets/images/logo.svg";
import { useTranslation } from "react-i18next";

const SidebarMenu = ({ open, onClose, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const sideDrawerList = (anchor) => (
    <div
      className={classes.theSideBar}
      role="presentation"
      onClick={() => handleDrawerToggle(anchor, false)}
      onKeyDown={() => handleDrawerToggle(anchor, false)}
    ></div>
  );

  // TOKEN

  const [token, setToken] = useState([]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // HANDLE LLOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };

  // LANGUAGE

  // const [languageE1, setLanguageE1] = useState(null);
  // const languageopen = Boolean(languageE1);
  // const handleLanguage = (event) => {
  //   setLanguageE1(event.currentTarget);
  // };
  // const handleLanguageClose = () => {
  //   setLanguageE1(null);
  // };

  // const [langJson, setLangJson, removeLangJson] = useLocalStorage(
  //   "langJson",
  //   window.localStorage.getItem("language")
  // );

  // const [lang, setLang] = useState(
  //   window.localStorage.getItem("language")
  // );

  // const changeLang = (value) => {
  //   setLang(value.id);
  //   window.localStorage.setItem("language", value.id);
  //   window.location.reload();
  // };

  // NAVBAR ITEM

  const NAVBARLIST = {
    my: [
      {
        title: "HOME",
        link: "",
        login_required: false,
      },
      {
        title: "VIDEO",
        link: "videos",
        login_required: false,
      },
      {
        title: "NEWS",
        link: "news",
        login_required: false,
      },

      // {
      //   title: "PLANS",
      //   link: "plan",
      //   login_required: false,
      // },
      {
        title: "VOUCHER",
        link: "voucher",
        login_required: true,
      },

      {
        title: "CONTACT",
        link: "contact",
        login_required: false,
      },
      // {
      //   title: "REWARDS",
      //   link: "reward",
      //   login_required: false,
      // },
      {
        title: "PRODUCT",
        link: "product",
        login_required: false,
      },

      // {
      //   title: token == "" ? "JOIN MEMBER" : "MEMBERSHIP",
      //   link: token == "" ? "register" : "membership",
      //   login_required: false,
      // },
       {
        title: "ABOUT US",
        link: "about",
        login_required: false,
      },
      // {
      //   title: "JOIN MERCHANTS",
      //   link: "join",
      //   login_required: false,
      // },


    ],
    inr: [
      {
        title: "HOME",
        link: "",
        login_required: false,
      },
      {
        title: "VIDEO",
        link: "videos",
        login_required: false,
      },
      {
        title: "NEWS",
        link: "news",
        login_required: false,
      },

      // {
      //   title: "PLANS",
      //   link: "plan",
      //   login_required: false,
      // },
      {
        title: "VOUCHER",
        link: "voucher",
        login_required: true,
      },
      // {
      //   title: "REWARDS",
      //   link: "reward",
      //   login_required: false,
      // },
      {
        title: "CONTACT",
        link: "contact",
        login_required: false,
      },

      {
        title: "PRODUCT",
        link: "product",
        login_required: false,
      },
      {
        title: "ABOUT US",
        link: "about",
        login_required: false,
      },
      // {
      //   title: token == "" ? "JOIN MEMBER" : "MEMBERSHIP",
      //   link: token == "" ? "register" : "membership",
      //   login_required: false,
      // },
      // {
      //   title: "JOIN MERCHANTS",
      //   link: "join",
      //   login_required: false,
      // },


    ],
    en: [
      {
        title: "HOME",
        link: "",
        login_required: false,
      },

      {
        title: "NEWS",
        link: "news",
        login_required: false,
      },
      {
        title: "VIDEOS",
        link: "videos",
        login_required: false,
      },
      {
        title: "ABOUT US",
        link: "about",
        login_required: false,
      },


      // {
      //   title: "REWARDS",
      //   link: "reward",
      //   login_required: false,
      // },
      {
        title: "CONTACT",
        link: "contact",
        login_required: false,
      },
      // {
      //   title: "PLANS",
      //   link: "plan",
      //   login_required: false,
      // },
      {
        title: "VOUCHER",
        link: "voucher",
        login_required: true,
      },
      // {
      //   title: "JOIN MERCHANTS",
      //   link: "join",
      //   login_required: false,
      // },
      // {
      //   title: token == "" ? "JOIN MEMBER" : "MEMBERSHIP",
      //   link: token == "" ? "register" : "membership",
      //   login_required: false,
      // },
      {
        title: "PRODUCT",
        link: "product",
        login_required: false,
      },
    ],
    cn: [
      {
        title: "HOME",
        link: "",
        login_required: false,
      },


      // {
      //   title: "REWARDS",
      //   link: "reward",
      //   login_required: false,
      // },
      {
        title: "VOUCHER",
        link: "voucher",
        login_required: true,
      },
      // {
      //   title: "PLANS",
      //   link: "plan",
      //   login_required: false,
      // },
      {
        title: "CONTACT",
        link: "contact",
        login_required: false,
      },
      {
        title: "News",
        link: "news",
        login_required: false,
      },
      {
        title: "Video",
        link: "videos",
        login_required: false,
      },
      {
        title: "PRODUCT",
        link: "product",
        login_required: false,
      },
      {
        title: "ABOUT US",
        link: "about",
        login_required: false,
      },

      // {
      //   title: "JOIN MERCHANTS",
      //   link: "join",
      //   login_required: false,
      // },
      // {
      //   title: token == "" ? "JOIN MEMBER" : "MEMBERSHIP",
      //   link: token == "" ? "register" : "membership",
      //   login_required: false,
      // },
    ],
  };
  const NAVBARLIST2 = [];

  return (
    <>
      <Drawer anchor="left" open={open} onClose={onClose}>
        {sideDrawerList("left")}
        <List sx={{ padding: 0 }} component="nav">
          <NavbarSidebarList className={classes.thePattern}>
            <NavbarSidebarListLogo href="/">
              <img src={logo} />
            </NavbarSidebarListLogo>
            <CloseButtonBox>
              <a onClick={handleDrawerToggle}>
                <Close sx={{ color: "#FFF" }} />
              </a>
            </CloseButtonBox>

            <br />
            {NAVBARLIST[i18n.language].map((item) => {
              if (item.login_required && token == "") {
                return;
              }

              return (
                <ListItem>
                  <a
                    href={"/" + item.link}
                    style={{
                      color:
                        location.pathname.replace(/[^A-Za-z]+/g, "") ===
                          item.link
                          ? "#FFF"
                          : "#FFF",
                      fontWeight:
                        location.pathname.replace(/[^A-Za-z]+/g, "") ===
                          item.link
                          ? "700"
                          : "normal",
                    }}
                  >
                    {t(item.title)}
                  </a>
                </ListItem>
              );
            })}
            <Box>
              {token != "" && (
                <>
                  {NAVBARLIST2.map((item) => (
                    <ListItem>
                      <a
                        href={"/" + item.link}
                        style={{
                          color:
                            location.pathname.replace(/[^A-Za-z]+/g, "") ===
                              item.link
                              ? "#FFF"
                              : "#FFF",
                          fontWeight:
                            location.pathname.replace(/[^A-Za-z]+/g, "") ===
                              item.link
                              ? "700"
                              : "normal",
                        }}
                      >
                        {t(item.title)}
                      </a>
                    </ListItem>
                  ))}
                </>
              )}
            </Box>
          </NavbarSidebarList>
        </List>
      </Drawer>
    </>
  );
};

export default SidebarMenu;
