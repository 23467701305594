import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const Text = styled.p`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size};
  font-weight: ${(props) => props.bold};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  text-align: ${(props) => (props.center ? "center" : "left")};
  text-decoration: ${(props) => props.textDecoration};
`;

export default makeStyles(config);
