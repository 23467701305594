import React from "react";
import useStyles from "./styles";
import { useNavigate } from "react-router-dom";
import NavbarCentered from "../../components/Navbar/NavbarCentered/NavbarCentered";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import NavbarDropdown from "../../components/Navbar/NavbarDropdown/NavbarDropdown";
import Paragraph from "../../components/Typography/Paragraph";
import PageTitle from "../../components/Typography/PageTitle";
import Card from "../../components/Card/Card";
import { Button as ButtonMui } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Grid,
  MenuItem,
  Rating,
} from "@mui/material";
import Layout from "../../components/Layout/Layout";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import FormInputSelect from "../../components/TextField/FormInputSelect/FormInputSelect";
import Slider from "react-slick";
import homebanner from "../../assets/images/homebanner.png";
import ing001 from "../../assets/images/soybean.png";
import ing002 from "../../assets/images/ing002.png";
import ing003 from "../../assets/images/ing003.png";
import home001 from "../../assets/images/home001.png";
import home002 from "../../assets/images/home002.png";
import home003 from "../../assets/images/home003.png";
import home004 from "../../assets/images/home004.png";
import home005 from "../../assets/images/home_img_2.jpeg";
import home006 from "../../assets/images/bittergourd.png";
import home007 from "../../assets/images/surgery.png";
import homeicon001 from "../../assets/images/homeicon001.png";
import homeicon002 from "../../assets/images/homeicon002.png";
import logo from "../../assets/images/logo.svg";
import Button from "../../components/Button/Button";
import {
  ArrowDropDown,
  Facebook,
  Mail,
  NotificationsActive,
  Phone,
  Room,
} from "@mui/icons-material";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import {
  get_banners,
  get_product,
  get_testimonials,
  contact,
  subscribe,
  get_home_image,
} from "../../API/API";

export default function Home() {
  const classes = useStyles();
  const navigate = useNavigate();

  // CHNAGE LANGUAGE
  const { t, i18n } = useTranslation();

  const [banners, setBanners] = React.useState([]);

  // SLIDER SETTING
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
  };
  const [productInfo, setProductInfo] = React.useState(null);
  const [testimonial, setTestimonial] = React.useState([]);

  // EXPAND ACCORDION
  const [expanded, setExpanded] = React.useState(1);

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // HANDLECHANGE
  const handleChange = (e) => {
    setContactData({
      ...contactData,
      [e.target.name]: e.target.value,
    });
    setSubscribeData({
      ...subscribeData,
      [e.target.name]: e.target.value,
    });
  };

  i18n.on("languageChanged", () => { });

  // CONTACT
  const [contactData, setContactData] = React.useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleContact = () => {
    if (
      !contactData.email ||
      !contactData.name ||
      !contactData.contact ||
      !contactData.message
    ) {
      alert("All Field Required");
      return;
    }

    contact(contactData).then((json) => {
      if (json.status) {
        alert("Submit Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  // SUBSCRIBE
  const [subscribeData, setSubscribeData] = React.useState({
    email: "",
  });

  const handleSubscribe = () => {
    if (!subscribeData.email) {
      alert("All Field Required");
      return;
    }

    subscribe(subscribeData).then((json) => {
      if (json.status) {
        alert("Subscribed Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  const [comingsoon, setComingsoon] = React.useState(false);

  const [days, setDays] = React.useState(14);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [homeImg, setHomeImg] = React.useState(null);

  React.useEffect(() => {
    seconds === 0 && setSeconds(59);
    minutes === 0 && setMinutes(59);
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
        // SecondsCardRef.current.classList.toggle('rotate');
      }, 1000);
    }
    if (seconds === 0 && minutes > 0) {
      setMinutes(minutes - 1);
      // MinutesCardRef.current.classList.toggle('rotate');
    }
  }, [seconds, minutes]);
  React.useEffect(() => {
    hours === 0 && setHours(23);
    if (minutes === 0 && hours > 0) {
      setHours(hours - 1);
      // HoursCardRef.current.classList.toggle('rotate');
    }
  }, [minutes, hours]);
  React.useEffect(() => {
    days === 14 && setDays(13);
    hours === 0 && setDays(days - 1);
    // DaysCardRef.current.classList.toggle('rotate');
  }, [hours, days]);

  const get_data = async () => {
    var res = await get_product();
    if (res.status) {
      setProductInfo({
        ...res.data,
        name: {
          en: res.data.name,
          cn: res.data.name_cn,
          inr: res.data.name_inr,
          my: res.data.name_bm,
        },
        short_description: {
          en: res.data.short_description,
          cn: res.data.short_description_cn,
          inr: res.data.short_description_inr,
          my: res.data.short_description_bm,
        },
      });
    }

    var res_banner = await get_banners();
    if (res_banner.status) {
      var _banners = [];
      for (var i = 0; i < res_banner.data.length; i++) {
        var r = res_banner.data[i];
        _banners.push({
          image: r.image,
          link: r.link,
          title: {
            en: r.title_en,
            cn: r.title_cn,
            inr: r.title_inr,
            bm: r.title_bm,
          },
          description: {
            en: r.description_en,
            cn: r.description_cn,
            inr: r.description_inr,
            bm: r.description_bm,
          },
        });
      }
      setBanners(_banners);
    }

    var res_tes = await get_testimonials();
    if (res_tes.status) {
      setTestimonial(res_tes.data);
    }

    var res_home_img = await get_home_image();
    if (res_home_img.status) {
      setHomeImg(res_home_img.data);
    }
  };

  // const [videoSource, setVideoSource] = React.useState("");

  // React.useEffect(() => {
  //   const lang = window.localStorage.getItem("lang");

  //   const source = lang === "inr" ? "/vidinr.mp4" : "/vid.mp4";
  //   // setVideoSource(source);
  //   setVideoSource({
  //     en: source,
  //     cn: source,
  //     inr: source,
  //     my: source,
  //   });
  // }, []);

  // React.useEffect(() => {
  //   const lang = window.localStorage.getItem("lang");
  //   const source = lang === "inr" ? "/vidinr.mp4" : "/vid.mp4";
  //   setVideoSource(source);
  // }, [i18n.language]);

  // console.log(i18n.language)

  React.useEffect(() => {
    get_data();

    var windowUrl = window.location.search;
    var params = new URLSearchParams(windowUrl);
    if (params.get("r")) {
      window.localStorage.setItem("referrer", params.get("r"));
    }
  }, []);

  return (
    <>
      {comingsoon ? (
        <>
          <div className={classes.theComingBG}>
            <div>
              <div className={classes.theLogo}>
                <img src={logo} />
              </div>
              <Paragraph
                color="#FFF"
                bold="700"
                size="34px"
                margin="0 0 2rem 0"
                center
              >
                WeHealthy - Royal Bitter Gourd Peptide
              </Paragraph>
              <Paragraph
                color="#FFF"
                bold="700"
                size="54px"
                margin="0 0 2rem 0"
                center
              >
                WE ARE COMING SOON
              </Paragraph>
              {/* <Countdown date={Date.now() + 100000000}></Countdown> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "75%",
                  margin: "0 auto",
                }}
              >
                <div>
                  <div className={classes.theCardTimer}>
                    <Paragraph color="#194234" bold="700" size="32px" center>
                      {days}
                    </Paragraph>
                  </div>
                  <Paragraph margin="1rem 0" color="#FFF" center>
                    DAYS
                  </Paragraph>
                </div>
                <div>
                  <div className={classes.theCardTimer}>
                    <Paragraph color="#194234" bold="700" size="32px" center>
                      {hours}
                    </Paragraph>
                  </div>
                  <Paragraph margin="1rem 0" color="#FFF" center>
                    HOURS
                  </Paragraph>
                </div>

                <div>
                  <div className={classes.theCardTimer}>
                    <Paragraph color="#194234" bold="700" size="32px" center>
                      {minutes}
                    </Paragraph>
                  </div>
                  <Paragraph margin="1rem 0" color="#FFF" center>
                    MINUTES
                  </Paragraph>
                </div>
                <div>
                  <div className={classes.theCardTimer}>
                    <Paragraph color="#194234" bold="700" size="32px" center>
                      {seconds}
                    </Paragraph>
                  </div>
                  <Paragraph margin="1rem 0" color="#FFF" center>
                    SECONDS
                  </Paragraph>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <React.Fragment>
          <NavbarLefted />
          {banners.length > 0 && (
            <Slider className={classes.root} {...settings}>
              {banners.map((r) => (
                <a href={r.link}>
                  <div className={classes.theBanner}>
                    <img
                      src={r.image[i18n.language]}
                      style={{
                        height: "auto",
                      }}
                    />
                    <div className={classes.theOverlayBox}></div>
                  </div>
                </a>
              ))}
            </Slider>
          )}

          <Layout pd="3rem 0">
            {window.localStorage.getItem("lang") == "en" && (
              <Paragraph
                color="#194234"
                bold="700"
                // size="20px"
                className={classes._theFontTitle}
                margin="0 0 4rem 0"
                center
              >
                {t("Our Ingredients")}
              </Paragraph>
            )}
            {(window.localStorage.getItem("lang") == "my" ||
              i18n.language == "inr") && (
                <Paragraph
                  color="#194234"
                  bold="700"
                  // size="20px"
                  className={classes._theFontTitle}
                  margin="0 0 4rem 0"
                  center
                >
                  {t("Our Ingredients")}
                </Paragraph>
              )}
            {window.localStorage.getItem("lang") == "cn" && (
              <Paragraph
                color="#194234"
                bold="700"
                size="26px"
                margin="0 0 4rem 0"
                center
              >
                {t("Our Ingredients")}
              </Paragraph>
            )}

            <Grid container spacing={3} sx={{ justifyContent: "center" }}>
              <Grid item xs={12} sm={4} md={4}>
                <div className={classes.theIngBox}>
                  <div className={classes.theIngIMG}>
                    <img src={home006} />
                  </div>
                  <Paragraph size="20px" margin="1rem 0" center>
                    {t("Wild Bitter Gourd Peptide_home")}
                  </Paragraph>
                  <Paragraph size="17px" center>
                    {t(
                      "Improving the body's immunity and stimulating the ability of macrophages to phagocytose tumor cells is clinically effective for lymphosarcoma and leukemia."
                    )}
                  </Paragraph>
                </div>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <div className={classes.theIngBox}>
                  <div className={classes.theIngIMG}>
                    <img src={ing001} />
                  </div>
                  <Paragraph size="20px" margin="1rem 0" center>
                    {t("Soy Protein")}
                  </Paragraph>
                  <Paragraph size="17px" center>
                    {t(
                      "Helps insulin, activates insulin receptors, and helps regulate blood sugar by improving insulin utilization"
                    )}
                  </Paragraph>
                </div>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <div className={classes.theIngBox}>
                  <div className={classes.theIngIMG}>
                    <img src={home007} />
                  </div>
                  <Paragraph size="20px" margin="1rem 0" center>
                    {t("Technology")}
                  </Paragraph>
                  <Paragraph size="17px" center>
                    {t(
                      "We utlilize German technologies for research and development, and using the modern advanced extraction technology"
                    )}
                  </Paragraph>
                </div>
              </Grid>
            </Grid>
          </Layout>
          {productInfo && (
            <Layout>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "flex",
                  },
                }}
              >
                <div className={classes.theTextBox}>
                  <div className={classes.theTextBoxInner}>
                    <Paragraph
                      color="#194234"
                      bold="700"
                      margin="0 0 1rem 0"
                      className={classes.theTitle}
                    >
                      {productInfo.name[i18n.language]}
                    </Paragraph>
                    <Paragraph
                      bold="700"
                      color="#000"
                      margin="0 0 1rem 0"
                      className={classes.thePrice}
                    >
                      MYR {productInfo.price}
                    </Paragraph>
                    <Paragraph
                      margin="0 0 1rem 0"
                      className={classes.theDetail}
                      style={{ whiteSpace: "break-spaces" }}
                    // dangerouslySetInnerHTML={{
                    //   __html: productInfo.short_description[i18n.language],
                    // }}
                    >
                      {productInfo.short_description[i18n.language]}
                    </Paragraph>
                  </div>
                </div>
                <div className={classes.theIMG}>
                  {homeImg && <img src={homeImg.image[i18n.language]} />}
                </div>
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "flex",
                  },
                }}
              >
                <div className={classes.theIMG_}>
                  {/* <img src='./home_006.jpg' /> */}
                  {window.localStorage.getItem("lang") == "inr" && (
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/sBoV2JBC9m0"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}

                  {window.localStorage.getItem("lang") == "cn" && (
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/hUtztkhuBMI"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}

                  {window.localStorage.getItem("lang") == "my" && (
                    // <iframe
                    //   width="560"
                    //   height="315"
                    //   src="https://www.youtube.com/embed/AuC3310_GJM"
                    //   title="YouTube video player"
                    //   frameborder="0"
                    //   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    //   allowfullscreen
                    // ></iframe>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/30iBNwVo0LQ?si=5MYMU4pPgKnaIWj5"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}

                  {window.localStorage.getItem("lang") == "en" && (
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/jylAiEco4KM"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  )}

                  {/* <video
                    style={{
                      width: "100%",
                    }}
                    controls={true}
                    autoPlay={true}
                  >
                    <source src={videoSource[i18n.language]} type="video/mp4" />
                  </video> */}
                  {/* <video
                    style={{
                      width: "100%",
                    }}
                    controls={true}
                    autoPlay={true}
                  >
                    <source src={videoSource} type="video/mp4" />
                  </video> */}
                  {/* <p>{videoSource[i18n.language]}</p> */}
                </div>
                <div className={classes.theTextBox}>
                  <div className={classes.theTextBoxInner}>
                    <Paragraph
                      color="#194234"
                      bold="700"
                      margin="0 0 1rem 0"
                      className={classes.theTitle}
                    >
                      {t("How To Use")}
                    </Paragraph>

                    <Accordion
                      expanded={expanded === 1}
                      onChange={handleChangeAccordion(1)}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                        aria-controls="1"
                        id="1"
                      >
                        <Paragraph
                          color="#707070"
                          className={classes.theDetail}
                        >
                          {t("Method 1")}
                        </Paragraph>
                      </AccordionSummary>
                      <AccordionDetails sx={{ marginBottom: "1rem" }}>
                        <Paragraph margin="0 0 .4rem 0">
                          {t(
                            "Cool boiling water at room temperature (25-30 degrees,about 180ml) into the bowl (water at room temperature will be easier to drink)"
                          )}
                        </Paragraph>
                        <Paragraph margin="0 0 .4rem 0">
                          {t(
                            "No need to stir, the peptide will naturally dissolve automatically."
                          )}
                        </Paragraph>
                        <Paragraph margin="0 0 .4rem 0">
                          {t(
                            "You can drink it before and after meals, it will be better to eat your own health care products or medicines after drinking for 10 minutes,"
                          )}
                        </Paragraph>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion
                      expanded={expanded === 2}
                      onChange={handleChangeAccordion(2)}
                    >
                      <AccordionSummary
                        expandIcon={<ArrowDropDown style={{ color: "#000" }} />}
                        aria-controls="2"
                        id="2"
                      >
                        <Paragraph
                          color="#707070"
                          className={classes.theDetail}
                        >
                          {t("Method 2")}
                        </Paragraph>
                      </AccordionSummary>
                      <AccordionDetails sx={{ marginBottom: "1rem" }}>
                        <Paragraph margin="0 0 .4rem 0">
                          {t(
                            "If there is blood or pus in the wound, it can be poured directly to cover the wound. If the wound has no moisture, add water first and then apply wild bitter gourd small molecular peptide and rub it on the wound Very effective"
                          )}
                        </Paragraph>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </Box>
            </Layout>
          )}

          <Layout>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8}>
                <Paragraph
                  color="#194234"
                  bold="700"
                  size="32px"
                  margin="0 0 3rem 0"
                >
                  {t("Why Choose Royal Bitter Gourd Peptide ?")}
                </Paragraph>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: ".4rem",
                        }}
                      >
                        {/* <div className={classes.theIcon}>
                          <img src={homeicon001} />
                        </div> */}
                        <Paragraph
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {t("REASON 1")}
                        </Paragraph>
                      </div>
                      <Paragraph>
                        {t(
                          "Pure wild bitter gourd, compatible with high-quality plant separation and extraction technology, using scientific formula of compound aromatic plant protease, compound substrate for catalysis, oligopeptide amino acid (300 molecular weight)"
                        )}
                      </Paragraph>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: ".4rem",
                        }}
                      >
                        <Paragraph
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {t("REASON 2")}
                        </Paragraph>
                      </div>
                      <Paragraph>
                        {t(
                          "Improving the body's immunity and stimulating the ability of macrophages to phagocytose tumor cells is clinically effective for lymphosarcoma and leukemia."
                        )}
                      </Paragraph>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: ".4rem",
                        }}
                      >
                        <Paragraph
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {t("REASON 3")}
                        </Paragraph>
                      </div>
                      <Paragraph>
                        {t(
                          "The content of vitamin C in bitter gourd is very high, which can prevent scurvy, protect cell membranes, prevent atherosclerosis, improve the body's stress ability, and protect the heart."
                        )}
                      </Paragraph>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: ".4rem",
                        }}
                      >
                        <Paragraph
                          style={{
                            fontWeight: "bold",
                            fontSize: 24,
                          }}
                        >
                          {t("REASON 4")}
                        </Paragraph>
                      </div>
                      <Paragraph>
                        {t(
                          "Diuretic and blood circulation, anti-inflammatory and antipyretic, clearing the heart and improving eyesight, can increase appetite, invigorate the spleen and whet the appetite"
                        )}
                      </Paragraph>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className={classes.theIMG2}>
                  <img src={home003} />
                </div>
              </Grid>
            </Grid>
          </Layout>
          <Box sx={{ background: "#F2F2F2" }}>
            <Layout>
              <Paragraph
                color="#194234"
                size="32px"
                bold="700"
                margin="0 0 2rem 0"
              >
                {t("What Our Clients Say?")}
              </Paragraph>
              <Slider className={classes.root2} {...settings}>
                {testimonial.map((r) => (
                  <div>
                    <Grid container spacing={6}>
                      <Grid item xs={12} sm={12} md={8}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "2rem",
                          }}
                        >
                          <div className={classes.theIcon2}>
                            <img src={homeicon002} />
                          </div>
                          <div style={{ width: "80%" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "center",
                              }}
                            >
                              {/* {r.state != "" ? (
                                <Paragraph size="16px">
                                  {r.state}, {r.country}
                                </Paragraph>
                              ) : (
                                <Paragraph size="16px">{r.country}</Paragraph>
                              )} */}
                              <Paragraph size="16px">{r.country}</Paragraph>
                              <Rating value={r.rating} />
                            </div>
                            <Paragraph
                              margin="0 0 .4rem 0"
                              color="#194234"
                              bold="700"
                              size="14px"
                            >
                              {r.name}
                            </Paragraph>
                          </div>
                        </div>
                        <Paragraph>{r.description}</Paragraph>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.theIMG2}>
                          <img src={r.image} />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Slider>
            </Layout>
          </Box>
          <Box className={classes.thePattern}>
            <Layout>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                  <div>
                    <Paragraph
                      color="#FFF"
                      size="32px"
                      bold="700"
                      margin="0 0 1rem 0"
                    >
                      {t("Get in touch with us")}
                    </Paragraph>
                    <Paragraph color="#FFF" size="14px"></Paragraph>
                    <br />
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.theCircle}>
                        <Phone sx={{ color: "#333" }} />
                      </div>

                      <Paragraph color="#FFF">
                        <span
                          style={{
                            fontWeight: "bold",
                            marginBottom: ".4rem",
                            display: "block",
                          }}
                        >
                          {t("Malaysia HQ Office")}
                        </span>
                        {/* <br /> */}
                        <a
                          style={{
                            color: "#FFF",
                            display: "block",
                            // marginRight: "10px",
                            marginBottom: ".4rem",
                          }}
                          href="tel:+6588288815"
                        >
                          +65 8828 8815
                        </a>

                      </Paragraph>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.theCircle}>
                        <Mail sx={{ color: "#333" }} />
                      </div>
                      <Paragraph color="#FFF">
                        <a
                          style={{
                            color: "#FFF",
                          }}
                          href="mailto:wehealthymanagement@gmail.com"
                        >
                          wehealthymanagement@gmail.com
                        </a>
                      </Paragraph>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.theCircle}>
                        <Room sx={{ color: "#333" }} />
                      </div>
                      <Paragraph color="#FFF">
                        <a
                          href="https://goo.gl/maps/NtXGND55PWEPugJHA"
                          style={{ color: "white" }}
                        >
                          APT/BLK 3 Beach Road <br />
                          #01-4835 <br />
                          Singapore 190003
                        </a>
                      </Paragraph>
                    </div>
                    <br />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className={classes.theCircle_}>
                        <Facebook sx={{ color: "#333" }} />
                      </div>
                      <Box sx={{ width: { xs: "80%", md: "100%" } }}>
                        <Paragraph
                          color="#FFF"
                          style={{ wordWrap: "break-word" }}
                        >
                          <a
                            href="https://www.facebook.com/RoyalBitterGourdPeptide/"
                            style={{
                              color: "#FFF",
                            }}
                          >
                            https://www.facebook.com/RoyalBitterGourdPeptide/
                          </a>
                        </Paragraph>
                      </Box>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Card bg="#FFF" pd="2rem">
                    <Paragraph color="#707070" size="24px" margin="0 0 1rem 0">
                      {t("Send us a message")}
                    </Paragraph>
                    <FormInput
                      label={t("Name")}
                      placeholder={t("Name")}
                      name="name"
                      onChange={handleChange}
                    />
                    <FormInput
                      label={t("Email")}
                      placeholder={t("Email")}
                      name="email"
                      onChange={handleChange}
                    />
                    <FormInput
                      label={t("Contact Number")}
                      placeholder={t("Contact Number")}
                      // type="number"
                      name="contact"
                      onChange={handleChange}
                    />
                    <FormInput
                      label={t("Message")}
                      placeholder={t("Message")}
                      // pd=".475rem 1rem"
                      rows="3"
                      multiline
                      name="message"
                      onChange={handleChange}
                    />
                    <br />
                    <Button
                      color="#FFF"
                      bg="#4FCEA1"
                      label={t("SEND")}
                      size="14px"
                      br="4px"
                      pd=".6rem 1rem"
                      onClick={() => handleContact()}
                    />
                  </Card>
                </Grid>
              </Grid>
            </Layout>
          </Box>
          <div className={classes.theBG}>
            <Layout pd="2rem 0">
              <Box sx={{ width: { xs: "100%", md: "70%" }, margin: "0 auto" }}>
                <Paragraph color="#FFF" size="28px" margin="0 0 1rem 0" center>
                  {t("Subscribe To Get Update")}
                </Paragraph>
                <Paragraph
                  color="#FFF"
                  size="14px"
                  margin="0 0 1rem 0"
                  center
                ></Paragraph>
                <br />
                <FormInputNoLabel
                  placeholder={t("Your Email Address")}
                  bg="#FFF"
                  br="4px"
                  onChange={(e) => {
                    setSubscribeData({
                      email: e.target.value,
                    });
                  }}
                  pd="14.5px 14px"
                  name="email"
                  InputProps={{
                    endAdornment: (
                      <ButtonMui
                        onClick={() => handleSubscribe()}
                        color="error"
                        variant="contained"
                        style={{
                          whiteSpace: "nowrap",
                          paddingLeft: 30,
                          paddingRight: 30,
                          fontSize: "0.7rem",
                        }}
                      >
                        {t("SUBSCRIBE")}{" "}
                        <NotificationsActive
                          style={{
                            marginLeft: 6,
                          }}
                        />
                      </ButtonMui>
                      // <Button
                      //   color="#FFF"
                      //   bg="#ce4f4f"
                      //   style={{
                      //     whiteSpace: "nowrap"
                      //   }}
                      //   label={(<>t("SUBSCRIBE") <NotificationsActive /></>)}
                      //   size="14px"
                      //   br="4px"
                      //   pd=".4rem 3rem"
                      //   onClick={() => handleSubscribe()}
                      // />
                    ),
                  }}
                />
              </Box>
            </Layout>
          </div>
          <Footer />
        </React.Fragment>
      )}
    </>
  );
}
