import React, { useRef } from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Card, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { get_video_details } from "../../API/API";
import reward from "../../assets/images/reward.png";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

export default function VideoDetail() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const params = useParams();
  const slider1 = useRef();
  const slider2 = useRef();
  const [tabsPhoto, setTabsPhoto] = React.useState(0);

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };

  const [videoInfo, setVideoInfo] = React.useState(null);

  const get_data = async () => {
    var video_id = params.video_id;
    var res = await get_video_details({ video_id: video_id });

    if (res.status) {
      // if (res.data.images.length > 0) {
      //   setTabsPhoto(res.data.images[0].image);
      // }
      // setVideoInfo(res.data);
      setVideoInfo({
        ...res.data,
        title: {
          en: res.data.title,
          cn: res.data.title_cn,
          my: res.data.title_bm,
          inr: res.data.title_inr
        },
        video: {
          en: decodeURIComponent(res.data.video).replace(/\+/g, ' '),
          cn: decodeURIComponent(res.data.video_cn).replace(/\+/g, ' '),
          my: decodeURIComponent(res.data.video_bm).replace(/\+/g, ' '),
          inr: decodeURIComponent(res.data.video_inr).replace(/\+/g, ' '),
        },
      });

      console.log(decodeURIComponent(res.data.video).replace(/\+/g, ' '));
    }
  };

  React.useEffect(() => {
    get_data();
  }, []);

  const iframeRef = React.useRef(null);
  const defaultHeight = 495;
  const [videoHeight, setVideoHeight] = React.useState(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );

  const handleChangeVideoWidth = React.useCallback(() => {
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height * ratio));
  }, []);

  React.useEffect(() => {
    window.addEventListener("resize", handleChangeVideoWidth);
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height * ratio));
    return function cleanup() {
      window.removeEventListener("resize", handleChangeVideoWidth);
    };
  }, [videoHeight, handleChangeVideoWidth]);

  // console.log(decodeURIComponent(videoInfo.video[i18n.language]))

  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("Video")}
          </Paragraph>
        </Layout>
      </div>
      {videoInfo && (
        <Layout>
          <Box sx={{ padding: ".4rem 1rem" }}>
            {/* <iframe
              ref={iframeRef}
              width="100%"
              height={`${videoHeight}px`}
              src= "https://www.youtube.com/embed/v7qwG-8-M6E"
              allowFullScreen
              style={{ border: 0 }}
            ></iframe> */}
            <div
              style={{ width: "100%" }}
              dangerouslySetInnerHTML={{
                __html: videoInfo.video[i18n.language],
              }}
            />

            <br />
            <Paragraph size="24px" color="#000" bold="700" margin="0 0 1rem 0">
              {videoInfo.title[i18n.language]}
            </Paragraph>
          </Box>
        </Layout>
      )}

      <Footer />
    </React.Fragment>
  );
}
