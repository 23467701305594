import React from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { contact } from "../../API/API";

export default function About() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("About Us")}
          </Paragraph>
        </Layout>
      </div>
      <Layout>
        <Box>
          <Paragraph
            color="#194234"
            bold="700"
            size="32px"
            margin="0 0 4rem 0"
            center
          >
            {t("About")} We Healthy Management Sdn. Bhd.
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("desp001")}
          </Paragraph>
          <br />
          <br />
          <br />
          <Paragraph
            color="#194234"
            bold="700"
            size="32px"
            margin="0 0 4rem 0"
            center
          >
            {t("Founder's Story")}
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("story001")}
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("story002")}
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("story003")}
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("story004")}
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">
            {t("story005")}
          </Paragraph>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
