// import axios from "axios";

import axios from "axios";

const host = "https://wehealthysingapore.com/admin/";
// const host = "http://178.128.120.86/r      oyalbittergroup/";
// const host = "http://localhost/rbg_admin/";
const access_endpoint_link = host + "API/";

const format_request = (postparam) => {
  if (postparam) {
    let token = window.localStorage.getItem("token");
    if (token) {
      postparam["token"] = token;
    }
  }

  //language
  var Lang = "en";
  let Lang_res = window.localStorage.getItem("lang");
  if (Lang_res) {
    Lang = Lang_res;
  }
  if (Lang) {
    postparam["lang"] = Lang;
  }

  let body = "";
  for (let k in postparam) {
    body += encodeURI(k) + "=" + encodeURI(postparam[k]) + "&";
  }
  var postOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      // 'authorization': `Bearer ${token}`
    },
    body: body,
  };
  return postOptions;
};

const api_post = async (postparam = {}, end_point) => {
  var response = await fetch(
    access_endpoint_link + end_point,
    format_request(postparam)
  );

  var json = await response.json();
  if (json.status) {
    return json;
  } else {
    // if (json.message == "Please login again") {
    //   window.localStorage.clear();
    //   alert(json.message);
    //   window.location.href = "/login";
    //   return json;
    // } else {
    //     alert(json.message);
    // }
    return json;
  }
};

export const login = async (postparam) => {
  return api_post(postparam, "login");
};

export const get_profile = async () => {
  return api_post({}, "get_profile");
};

export const change_password = async (postparam) => {
  return api_post(postparam, "change_password");
};

export const register = async (postparam) => {
  var referrer = window.localStorage.getItem("referrer");
  if (referrer != "" && referrer != undefined && referrer != null) {
    postparam["referrer"] = referrer;
  }
  return api_post(postparam, "register");
};

export const get_customer_profile = async (postparam) => {
  return api_post(postparam, "get_customer_profile");
};

export const get_product = async () => {
  return api_post({}, "get_product");
};
export const get_partners = async () => {
  return api_post({}, "get_partners");
};

export const checkout = async (data) => {
  if (
    window.localStorage.getItem("referrer") != null &&
    window.localStorage.getItem("referrer") != undefined &&
    window.localStorage.getItem("referrer")
  ) {
    data["referrer"] = window.localStorage.getItem("referrer");
  }
  return api_post(data, "checkout");
};

export const validate_cart = async (data) => {
  if (
    window.localStorage.getItem("referrer") != null &&
    window.localStorage.getItem("referrer") != undefined &&
    window.localStorage.getItem("referrer")
  ) {
    data["referrer"] = window.localStorage.getItem("referrer");
  }
  return api_post(data, "validate_cart");
};

export const get_banners = async () => {
  return api_post({}, "get_banners");
};

export const get_testimonials = async () => {
  return api_post({}, "get_testimonials");
};

export const get_plans = async () => {
  return api_post({}, "get_plans");
};

export const edit_profile = async (data) => {
  return api_post(data, "edit_profile");
};

export const get_point_list = async (data) => {
  return api_post(data, "get_point_list");
};

export const get_orders = async () => {
  return api_post({}, "get_orders");
};

export const get_downline = async () => {
  return api_post({}, "get_downline");
};

export const get_vouchers = async () => {
  return api_post({}, "get_vouchers");
};

export const get_voucher_info = async (data) => {
  return api_post(data, "get_voucher_info");
};

export const claim_voucher = async (data) => {
  return api_post(data, "claim_voucher");
};

export const get_member_voucher = async () => {
  return api_post({}, "get_member_voucher");
};

export const contact = async (postparam) => {
  return api_post(postparam, "contact");
};

export const subscribe = async (postparam) => {
  return api_post(postparam, "subscribe");
};

export const forgot_password = async (postparam) => {
  return api_post(postparam, "forgot_password");
};

export const feedback = async (postparam) => {
  return api_post(postparam, "feedback");
};

export const get_news = async () => {
  return api_post({}, "get_news");
};

export const get_news_details = async (data) => {
  return api_post(data, "get_news_details");
};

export const join_merchant = async (postparam) => {
  return api_post(postparam, "join_merchant");
};

export const get_order_info = async (postparam) => {
  return api_post(postparam, "get_order_info");
};

export const upload_orders_receipt = (postparam) => {
  return axios.post(access_endpoint_link + "upload_orders_receipt", postparam, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const update_orders_receipt = (postparam) => {
  return api_post(postparam, "update_orders_receipt");
};

export const submit_withdrawal = async (postparam) => {
  return api_post(postparam, "submit_withdrawal");
};

export const get_home_image = async () => {
  return api_post({}, "get_home_image");
};

export const get_last_purchase = async () => {
  return api_post({}, "get_last_purchase");
};

export const get_videos = async () => {
  return api_post({}, "get_videos");
};

export const get_video_details = async (data) => {
  return api_post(data, "get_video_details");
};
