import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ContentCopy,
  Delete,
  Facebook,
  Instagram,
  Twitter,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import referral001 from "../../assets/images/referral001.png";
import { useTranslation } from "react-i18next";
import toast from "react-simple-toasts";
import { get_downline, get_profile } from "../../API/API";

export default function Referral() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();
  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // REFERRAL LIST
  const [referralList, setReferralList] = React.useState([]);

  const getReferralList = () => {
    // referral({ token: token }).then((json) => {
    //   setReferralList(json.data);
    // });
  };


  const [profileData, setProfileData] = React.useState({});
  const [downlines, setDownlines] = React.useState([]);
  React.useEffect(() => {
    getProfileData();
  }, []);
  const handleCopy = () => {
    navigator.clipboard.writeText("https://" + window.location.host + "/?r=" + profileData.code);
    toast("Copied to clipboard");
  };
  const getProfileData = async () => {

    var res = await get_profile();
    if (res.status) {
      setProfileData(res.data);
    } else {
      alert("Please log in again");
      handleLogout();
    }

    var res_d = await get_downline();
    if (res_d.status) {
      setDownlines(res_d.data);
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };

  React.useEffect(() => {
    getReferralList();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a
                  className={clsx(classes.theNavTab)}
                  href="/dashboard"
                >
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/membership">
                  {t("My Membership")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>
                {/* <a className={clsx(classes.theNavTab)} href="/address">
                  {t("Address Book")}
                </a> */}
                <a className={clsx(classes.theNavTab, classes.theActive)} href="/referral">
                  {t("My Referral")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>

              <Card pd="1.5rem" shadow>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={6}>
                    <Paragraph color="#194234" bold="700" margin="0 0 .4rem 0">
                      {t("SHARE YOUR UNIQUE CODE")}
                    </Paragraph>
                    <Paragraph size="14px" margin="0 0 1rem 0">
                      {t("Get points when your friend purchase")}
                    </Paragraph>
                    <FormInputNoLabel
                      value={"https://" + window.location.host + "/?r=" + profileData.code}
                      bold="700"
                      bg="#FFF"
                      disabled={true}
                      br="4px"
                      pd="1rem"
                      InputProps={{
                        endAdornment: (
                          <Button
                            color="#FFF"
                            bg="#4FCEA1"
                            onClick={handleCopy}
                            label={<ContentCopy sx={{ display: "flex" }} />}
                            br="4px"
                          />
                        ),
                      }}
                    />
                    {/* <div style={{ display: "flex" }}>
                      <a href="" target="_blank">
                        <Facebook style={{ color: "#194234" }} />
                      </a>
                      <a href="" target="_blank">
                        <Instagram
                          style={{ color: "#194234", margin: "0 1rem" }}
                        />
                      </a>
                      <a href="" target="_blank">
                        <Twitter style={{ color: "#194234" }} />
                      </a>
                    </div> */}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div style={{ display: "flex", float: "right" }}>
                      <img src={referral001} />
                    </div>
                  </Grid>
                </Grid>
              </Card>
              <br />
              <br />
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("My Downline")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>No.</TableCell>
                          <TableCell>{t("Member No")}</TableCell>
                          <TableCell>{t("Name")}</TableCell>

                          <TableCell>{t("Points")}</TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {downlines.map((r, index) => (
                          <TableRow>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{r.wh_code}</TableCell>
                            <TableCell>{r.name}</TableCell>
                            <TableCell>{r.points}</TableCell>

                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
