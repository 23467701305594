// import React, { createContext, useState } from "react";

// export const AuthContext = React.createContext();

// const storage = localStorage.getItem("user")
//   ? JSON.parse(localStorage.getItem("user"))
//   : [];

// const AuthContextProvider = (props) => {
//   const [auth, setAuth] = useState(storage);

//   const initialState = async () => {
//     const userData = window.localStorage.getItem("user");
//     if (userData) {
//       setAuth(JSON.parse(userData));
//     } else {
//       setAuth(null);
//     }
//   };

//   React.useEffect(() => {
//     initialState();
//   }, []);

//   return (
//     <AuthContext.Provider value={[auth, setAuth]}>
//       {props.children}
//     </AuthContext.Provider>
//   );
// };

// export default AuthContextProvider;

import React, { createContext, useState, useMemo } from "react";

export const AuthContext = createContext();

const AuthContextProvider = (props) => {
  const [auth, setAuth] = useState(null);

  React.useEffect(() => {
    const storage = localStorage.getItem("user");
    if (storage) {
      setAuth(JSON.parse(storage));
    }
  }, []);

  const contextValue = useMemo(() => [auth, setAuth], [auth]);

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
