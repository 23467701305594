import styled from "styled-components";
import { Menu, Search } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import { TextField, Input, FormControl } from "@mui/material";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png";

export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const FooterContainer = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  padding: 2rem 0;
  /* box-shadow: 1px -9px 5px -3px rgba(240,240,240,1); */
  position: relative;
  width: 100%;

  @media screen and (min-width: 992px) {
    padding: 2rem 0 1rem 0;
  }
`;

export const FooterList = styled.div`
  display: block;
  width: 100%;
`;


export const NavbarLogo = styled.a`
  width: 80px;
  @media screen and (min-width: 992px) {
    width: 80px;
  }
  @media screen and (min-width: 1440px) {
    width: 80px;
  }
  img {
    width: 80px;
    @media screen and (min-width: 992px) {
      width: 80px;
    }
    @media screen and (min-width: 1440px) {
      width: 80px;
    }
  }
`;

const config = {
  theBG: {
    position: "relative",
    backgroundImage: "url(" + home005 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },

  theBox: {
    position: "relative",
    width: "100%",
    ...mq("md", {
      width: "50%",
      margin: "0 auto",
    }),
  },

  theFooterLogo: {
    position: "relative",
    width: "35%",
    margin: "0 auto",
    marginBottom: ".4rem",

    ...mq("sm", {
      width: "20%",
    }),

    ...mq("md", {
      width: "25%",
    }),

    "& img": {
      width: "100%",
    },
  },

  theSubFooter: {
    background: "#00392c",
    padding: "1rem",
    margin: "0 auto",
  },

  
  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  thePayment: {
    position: "relative",
    width: "60%",
    
    "& img":{
      width: "100%"
    }
  },

  theSocialIMG:{
    position: "relative",
    width: "25%",
    "& img":{
      width: "100%"
    }
  }

};

export default makeStyles(config);
