import React, { useState } from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import reward001 from "../../assets/images/reward001.png";
import { get_member_voucher, get_profile } from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Membership() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // CHANGE TAB
  const [tab, setTab] = useState(0);
  const handleChangeTab = (tab) => {
    setTab(tab);
  };

  // MEMBERSHIP
  const [profileData, setProfileData] = React.useState({});

  const getProfileData = async () => {
    var res = await get_profile();
    if (res.status) {
      setProfileData(res.data);
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // VOUCHER LIST
  const [voucherList, setVoucherList] = React.useState([]);

  const getVoucherList = async () => {
    var res = await get_member_voucher();
    if (res.status) {
      setVoucherList(res.data);
    }
  };

  React.useEffect(() => {
    getVoucherList();
    getProfileData();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a className={clsx(classes.theNavTab)} href="/dashboard">
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                <a
                  className={clsx(classes.theNavTab, classes.theActive)}
                  href="/membership"
                >
                  {t("My Membership")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/address">
                  {t("Address Book")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/referral">
                  {t("My Referral")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {/* <Paragraph
                color="#194234"
                size="28px"
                bold="700"
                margin="0 0 1rem 0"
              >
                {t("Welcome")}
                {profileData.name}
              </Paragraph> */}
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("MY VOUCHER")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <div>
                    <a
                      className={
                        tab == 0 ? classes.theTabActive : classes.theTab
                      }
                      onClick={() => handleChangeTab(0)}
                    >
                      {t("Active Voucher")}
                    </a>
                    {/* <a
                      className={
                        tab == 1 ? classes.theTabActive : classes.theTab
                      }
                      onClick={() => handleChangeTab(1)}
                    >
                      {t("Past Voucher")}
                    </a> */}
                  </div>
                  <br />
                  <br />
                  {tab == 0 && (
                    <Grid container spacing={3}>
                      {voucherList.map((r) => {
                        if (new Date(r.expiry) > new Date() && r.is_used != 1) {
                          return (
                            <Grid item xs={12} sm={12} md={4}>
                              <Card pd="0" shadow>
                                <a href={"/voucherdetail/" + r.voucher_id}>
                                  <div className={classes.theIMG}>
                                    <img src={r.image} />
                                  </div>
                                  <div style={{ padding: "1rem" }}>
                                    <Paragraph
                                      color="#194234"
                                      bold="700"
                                      margin="0 0 .4rem 0"
                                    >
                                      {r.name}
                                    </Paragraph>
                                    <Paragraph color="#707070" size="12px">
                                      {t("Valid until")} {r.expiry}
                                    </Paragraph>
                                  </div>
                                </a>
                              </Card>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  )}
                  {/* {tab == 1 && (
                    <Grid container spacing={3}>
                      {voucherList.map((r) => {
                        if (new Date(r.expiry) < new Date() || r.is_used == "1" ) {
                          return (
                            <Grid item xs={12} sm={12} md={4}>
                              <Card pd="0" shadow>
                                <a href={"/voucherdetail/" + r.voucher_id}>
                                  <div className={classes.theIMG}>
                                    <img src={r.image} />
                                  </div>
                                  <div style={{ padding: "1rem" }}>
                                    <Paragraph
                                      color="#194234"
                                      bold="700"
                                      margin="0 0 .4rem 0"
                                    >
                                      {r.name}
                                    </Paragraph>
                                    <Paragraph color="#707070" size="12px">
                                      {t("Valid until")} {r.expiry}
                                    </Paragraph>
                                  </div>
                                </a>
                              </Card>
                            </Grid>
                          );
                        }
                      })}
                    </Grid>
                  )} */}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
