import React, { useContext } from "react";
import { CartContext } from "../context/CartContext";

const Storage = (cartItems) => {
  localStorage.setItem(
    "cart",
    JSON.stringify(cartItems.length > 0 ? cartItems : [])
  );
};

const useCart = () => {
  const [cart, setCart] = useContext(CartContext);

  function getCart() {
    return cart;
  }

  function clearCart() {
    setCart([]);
    Storage([]);
  }

  function setCartFunction(cart = []) {
    setCart(cart);
  }

  function addProduct(item) {
    // console.log(item);
    if (cart.length == 0) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var newItem = { ...item };

    newItem["index"] = cartTemp.length;
    cartTemp.push(newItem);

    setCart(cartTemp);
    Storage(cartTemp);
  }

  function getTotalCart() {
    var total_amount = 0;

    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }

    for (var i = 0; i < cartTemp.length; i++) {
      var arr = cartTemp[i].price * cartTemp[i].quantity;
      total_amount = parseFloat(total_amount) + parseFloat(arr);
    }
    return total_amount;
  }

  function removeProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    cartTemp.splice(index, 1);

    for (var i = 0; i < cartTemp.length; i++) {
      cartTemp[i]["index"] = i;
    }
    setCart(cartTemp);
    Storage(cartTemp);
  }

  function getPriceByQuantity(cartRowItem, qty) {
    return cartRowItem.single_price * qty;
  }

  function increaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) + 1;
    cartTemp[index]["quantity"] = quantity;
    cartTemp[index]["price"] = parseFloat(
      getPriceByQuantity(cartTemp[index], quantity)
    );
    setCart(cartTemp);
    Storage(cartTemp);
  }

  function decreaseProduct(index) {
    if (cart.length) {
      var cartTemp = [...cart];
    } else {
      var cartTemp = [];
    }
    var quantity = parseFloat(cartTemp[index].quantity) - 1;

    if (quantity > 0) {
      cartTemp[index]["quantity"] = quantity;
      cartTemp[index]["price"] = getPriceByQuantity(cartTemp[index], quantity);
    } else {
      cartTemp.splice(index, 1);
      for (var i = 0; i < cartTemp.length; i++) {
        cartTemp[i]["index"] = i;
      }
    }
    setCart(cartTemp);
    Storage(cartTemp);
  }

  return {
    addProduct,
    clearCart,
    getTotalCart,
    removeProduct,
    getCart,
    setCartFunction,
    decreaseProduct,
    increaseProduct,
  };
};

export default useCart;

// import React, { useContext } from "react";
// import { CartContext } from "../context/CartContext";

// const Storage = (cartItems) => {
//   localStorage.setItem(
//     "cart",
//     JSON.stringify(cartItems.length > 0 ? cartItems : [])
//   );
// };

// const useCart = () => {
//   const [cart, setCart] = useContext(CartContext);

//   function getCart() {
//     return cart;
//   }

//   function clearCart() {
//     setCart([]);
//     Storage([]);
//   }

//   function setCartFunction(cart = []) {
//     setCart(cart);
//   }

//   function addProduct(item) {
//     const tempCart = [...cart];
//     const newItem = { ...item, index: tempCart.length };
//     tempCart.push(newItem);
//     setCart(tempCart);
//     Storage(tempCart);
//   }

//   function getTotalCart() {
//     let totalAmount = 0;
//     cart.forEach(({ price }) => {
//       totalAmount += parseFloat(price);
//     });
//     return totalAmount;
//   }

//   function removeProduct(index) {
//     const tempCart = [...cart];
//     tempCart.splice(index, 1);
//     tempCart.forEach((item, i) => {
//       item.index = i;
//     });
//     setCart(tempCart);
//     Storage(tempCart);
//   }

//   function getPriceByQuantity({ single_price }, qty) {
//     return single_price * qty;
//   }

//   function updateQuantity(index, delta) {
//     const tempCart = [...cart];
//     const item = tempCart[index];
//     const newQuantity = item.quantity + delta;
//     if (newQuantity <= 0) {
//       tempCart.splice(index, 1);
//     } else {
//       item.quantity = newQuantity;
//       item.price = getPriceByQuantity(item, newQuantity);
//     }
//     tempCart.forEach((item, i) => {
//       item.index = i;
//     });
//     setCart(tempCart);
//     Storage(tempCart);
//   }

//   function increaseProduct(index) {
//     updateQuantity(index, 1);
//   }

//   function decreaseProduct(index) {
//     updateQuantity(index, -1);
//   }

//   return {
//     addProduct,
//     clearCart,
//     getTotalCart,
//     removeProduct,
//     getCart,
//     setCartFunction,
//     increaseProduct,
//     decreaseProduct,
//   };
// };

// export default useCart;