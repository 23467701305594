import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png";

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...mq("sm", {
          right: 100,
        }),

        ...mq("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...mq("sm", {
          left: 100,
        }),

        ...mq("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "-20px",
      ...mq("sm", {
        bottom: "-15px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#BCBCBC",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 30,
            backgroundColor: "#194234",
          },
        },
      },
    },
  },
  theProdIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },

  theBtnSizing: {
    position: "relative",
    width: "100%",

    ...mq("md", {
      width: "50%",
    }),
  },

  theHeight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    ...mq("sm", {
      height: "45vh",
    }),

    ...mq("xmd", {
      height: "55vh",
    }),

    ...mq("lg", {
      height: "45vh",
    }),
  },

  theClient: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...mq("sm", {
      width: "80%",
    }),
    ...mq("md", {
      width: "70%",
    }),
    "& img": {
      width: "100%",
    },
  },

  theIcon: {
    position: "relative",
    width: "15%",
    "& img": {
      width: "100%",
    },
  },

  theAvatar: {
    position: "relative",
    width: "10%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theBG: {
    position: "relative",
    backgroundImage: "url(" + home005 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },

  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theGalleryList: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    whiteSpace: "nowrap",


    "&::-webkit-scrollbar":{
      "webkitAppearance": "none",
      "width": "2px !important"
    },
    "&::-webkit-scrollbar-thumb": {
      "borderRadius": "0px",
      "backgroundColor": "rgba(0, 0, 0, .1)",
      "boxShadow": "0 0 1px rgba(255, 255, 255, .5)"
    }

  },
  theGallery: {
    position: "relative",
    marginRight: "1rem",
    cursor: "pointer",
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "contain"
    },
  },
};

export default makeStyles(config);
