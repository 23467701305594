import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png";

export const CircleCount = styled.div`
  position: relative;
  background: ${({ bg }) => bg};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 1rem;
`;

const config = {
  theCircle: {},

  theRewardIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  theMerchantIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      display: "flex",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
    },
  },
  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  theJoinIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  theIMG: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
  },
  theTripIMG: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },
};

export default makeStyles(config);
