import React from "react";
import Slider from "react-slick";
import useStyles from "./styles";
// import Navbar from "../../components/Navbar/Navbar";
// import Section from "../../components/Section/Section";
import Footer from "../../components/Footer/Footer";
// import FormInputNoLabel from "../../components/FormInputNoLabel/FormInputNoLabel";
import Paragraph from "../../components/Typography/Paragraph";
import Card from "../../components/Card/Card";

import clsx from "clsx";
import {
    Container,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Box,
    Tabs,
    Tab,
} from "@mui/material";
import {
    Add,
    ArrowBackIos,
    ArrowDropDown,
    ArrowForwardIos,
    Download,
    Remove,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import {
    get_order_info,
    get_profile,
    update_orders_receipt,
    upload_orders_receipt,
} from "../../API/API";
// import FormInput from "../../components/FormInput/FormInput";
import Loader from "react-js-loader";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Layout from "../../components/Layout/Layout";
import { useTranslation } from "react-i18next";
import toast from "react-simple-toasts";

export default function OrderHistoryDetail() {
    const classes = useStyles();
    let { sales_order_id } = useParams();

    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
    const [orderData, setOrderData] = React.useState([]);
    const [itemData, setItemData] = React.useState([]);
    const params = useParams();
    const [receiptImg, setReceiptImg] = React.useState("");

    const [selectedTab, setSelectedTab] = React.useState(0);
    const [profileData, setProfileData] = React.useState(null);
    const getProfileData = async () => {
        var res = await get_profile();
        if (res.status) {
            setProfileData(res.data);
        } else {
            alert("Please log in again");
            handleLogout();
        }
        // profile({ token: token }).then((json) => {
        //   setProfileData(json.data);
        // });
    };

    const getOrderData = async () => {
        // setLoading(true);
        var res = await get_order_info({
            orders_id: params.order_id,
        });

        if (res.status) {
            setOrderData(res.data);
            setItemData(res.data.items);
        }
    };

    const { t } = useTranslation();
    React.useEffect(() => {
        getOrderData();
        getProfileData();
    }, []);

    //

    const [desp, setDesp] = React.useState({
        description: "",
    });

    const handleChange = (e) => {
        setDesp({
            ...desp,
            [e.target.name]: e.target.value,
        });
    };

    const handleSave = () => {
        if (desp.description.length == "") {
            alert("All Field Required");
            return;
        } else {
        }
    };

    const [uploadPDF, setUploadPDF] = React.useState({
        sales_order_id: sales_order_id,
    });

    const handleChangeUpload = async (e) => {
        const file = e.target.files[0];
        var formData = new FormData();
        formData.append("image", file);

        // var res = await get_upload_file(formData);
        // if (res) {
        //     setUploadPDF({
        //         ...uploadPDF,
        //         image: res.data.data,
        //     });
        // }
    };

    const handleUpload = () => {
        // upload_consignment(uploadPDF).then((json) => {
        //     if (json.status) {
        //         alert("Sumitted");
        //     }
        // });
    };

    const handleReturn = (sales_order_id) => {
        if (desp.description.length == "") {
            alert("All Field Required");
            return;
        } else {
            // return_order({ ...desp, sales_order_id: sales_order_id }).then((json) => {
            //     if (json.status) {
            //         alert("Sumbitted");
            //     }
            // });
        }
    };

    const handleChangeFile = async (e) => {
        if (e.target.files) {
            var formdata = new FormData();
            console.log(e.target.files);
            formdata.append("file", e.target.files[0]);

            var res = await upload_orders_receipt(formdata);
            if (res.status) {
                setReceiptImg(res.data.data.base_url + res.data.data.thumb);
            }
        }
    };

    const submitReceipt = async () => {
        var data = {
            receipt: receiptImg,
            orders_id: params.order_id,
        };
        var res = await update_orders_receipt(data);
        if (res.status) {
            toast("Updated");
            getOrderData();
        }
    };
    const handleLogout = () => {
        window.localStorage.removeItem("token");
        navigate("/");
    };

    return (
        <React.Fragment>
            <NavbarLefted />
            <Layout>
                <Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3}>
                            <div className={classes.theNavList}>
                                <a className={clsx(classes.theNavTab)} href="/dashboard">
                                    {t("Dashboard")}
                                </a>
                                <a className={clsx(classes.theNavTab)} href="/profile">
                                    {t("My Profile")}
                                </a>
                                {profileData && (
                                    <>
                                        {profileData.is_confirmed_member == 1 && (
                                            <a className={clsx(classes.theNavTab)} href="/membership">
                                                {t("My Membership")}
                                            </a>
                                        )}
                                    </>
                                )}

                                <a
                                    className={clsx(classes.theNavTab, classes.theActive)}
                                    href="/order"
                                >
                                    {t("My Orders")}
                                </a>

                                {profileData && (
                                    <>
                                        {profileData.is_confirmed_member == 1 && (
                                            <a className={clsx(classes.theNavTab)} href="/referral">
                                                {t("My Referral")}
                                            </a>
                                        )}
                                    </>
                                )}

                                <a className={clsx(classes.theNavTab)} href="/setting">
                                    {t("Setting")}
                                </a>
                                <a
                                    className={clsx(classes.theNavTab)}
                                    onClick={() => handleLogout()}
                                >
                                    {t("Logout")}
                                </a>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={9}>
                            {loading == true ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "600px",
                                    }}
                                >
                                    <Loader
                                        type="snipper-default"
                                        bgColor={"#878787"}
                                        size={50}
                                    />
                                </div>
                            ) : (
                                <Container>
                                    <div pd="2rem 0">
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <a style={{ display: "flex", alignItems: "center" }}>
                                                <Paragraph size="14px" color="#878787" bold="600">
                                                    {t("My Profile")}
                                                </Paragraph>
                                                <ArrowForwardIos
                                                    style={{ fontSize: "12px", margin: "0 .4rem" }}
                                                />
                                                <Paragraph size="14px" color="#878787" bold="600">
                                                    {t("My Order")}
                                                </Paragraph>
                                                <ArrowForwardIos
                                                    style={{ fontSize: "12px", margin: "0 .4rem" }}
                                                />
                                                <Paragraph size="14px" color="#878787" bold="600">
                                                    {t("Order ID")}: {orderData.orders_id}
                                                </Paragraph>
                                            </a>
                                            <div>
                                                {/* <a
													style={{
														padding: ".4rem 1.5rem",
														border: "1px solid #878787",
														display: "flex",
													}}
													onClick={() => alert("Coming Soon")}
												>
													<Download sx={{ marginRight: "10px", color: "#878787" }} />
													<Paragraph color="#878787">DOWNLOAD</Paragraph>
												</a> */}
                                            </div>
                                        </div>
                                        <br />
                                        <Card style={{ borderRadius: "4px", padding: 0 }}>
                                            {orderData.receipt == "" && orderData.status_id == 1 && orderData.total_price != "0.00" && orderData.payment_type_id == 1 && (
                                                <>
                                                    <div>
                                                        <div
                                                            style={{
                                                                backgroundColor: "#4fcea1",
                                                                padding: "10px 20px",
                                                            }}
                                                        >
                                                            <h3
                                                                style={{
                                                                    color: "white",
                                                                }}
                                                            >
                                                                {t("Kindly bank in")} MYR{orderData.total_price}{" "}
                                                                {t("to one of the following channels")}
                                                            </h3>
                                                        </div>
                                                        <Tabs
                                                            value={selectedTab}
                                                            onChange={(e, v) => {
                                                                setSelectedTab(v);
                                                            }}
                                                        >
                                                            <Tab value={0} label={t("Bank Account")} />
                                                            <Tab value={1} label="Touch n' Go" />
                                                        </Tabs>
                                                        {selectedTab == 0 && (
                                                            <Box
                                                                style={{
                                                                    backgroundColor: "#f3f3f3",
                                                                    padding: "10px 20px",
                                                                }}
                                                            >
                                                                <h3 style={{ marginBottom: 0 }}>
                                                                    Hong Leong Bank
                                                                </h3>
                                                                <h4
                                                                    style={{
                                                                        marginTop: 10,
                                                                        marginBottom: 0,
                                                                    }}
                                                                >
                                                                    We Healthy Management Sdn Bhd
                                                                </h4>
                                                                <p
                                                                    style={{
                                                                        fontWeight: "bold",
                                                                    }}
                                                                >
                                                                    200-00-03654-8
                                                                </p>
                                                            </Box>
                                                        )}
                                                        {selectedTab == 1 && (
                                                            <Box
                                                                style={{
                                                                    backgroundColor: "#f3f3f3",
                                                                    padding: "10px 20px",
                                                                    textAlign: "center",
                                                                }}
                                                            >
                                                                <img
                                                                    style={{
                                                                        height: 300,
                                                                    }}
                                                                    src="/tng.jpeg"
                                                                ></img>
                                                            </Box>
                                                        )}
                                                    </div>
                                                    <div>
                                                        <h3>{t("Upload Receipt Here")}</h3>
                                                        {receiptImg != "" ? (
                                                            <>
                                                                <label for="receipt">
                                                                    <img
                                                                        style={{
                                                                            height: "100px",
                                                                        }}
                                                                        src={receiptImg}
                                                                    ></img>
                                                                </label>
                                                                <input
                                                                    id="receipt"
                                                                    style={{
                                                                        visibility: "hidden",
                                                                    }}
                                                                    name="receipt"
                                                                    onChange={handleChangeFile}
                                                                    type="file"
                                                                ></input>
                                                                <br />
                                                                <Button
                                                                    onClick={submitReceipt}
                                                                    variant="contained"
                                                                    style={{
                                                                        background: "#4fcea1",
                                                                    }}
                                                                >
                                                                    Submit Receipt
                                                                </Button>
                                                            </>
                                                        ) : (
                                                            <input
                                                                name="receipt"
                                                                onChange={handleChangeFile}
                                                                type="file"
                                                            ></input>
                                                        )}
                                                    </div>
                                                </>
                                            )}

                                            <br />
                                            <div style={{ padding: "1rem 0" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <Paragraph bold="700" size="22px" color="#878787">
                                                        {t("Order ID")}: {orderData.orders_id}
                                                    </Paragraph>
                                                    {orderData.status_id == 1 && (
                                                        <Paragraph
                                                            style={{
                                                                marginLeft: "auto",
                                                            }}
                                                            bold="700"
                                                            size="22px"
                                                            color="#878787"
                                                        >
                                                            {orderData.status}
                                                        </Paragraph>
                                                    )}
                                                </div>
                                            </div>
                                            <div style={{ borderBottom: "1px solid #d7d7d7" }} />
                                            <div style={{ padding: "1rem 0" }}>
                                                <Paragraph
                                                    bold="700"
                                                    size="14px"
                                                    color="#878787"
                                                    margin="0 0 .4rem 0"
                                                >
                                                    {t("Order Date")}:
                                                    <span
                                                        style={{ fontWeight: "400", marginLeft: "1rem" }}
                                                    >
                                                        {orderData.created_date}
                                                    </span>
                                                </Paragraph>
                                                <Paragraph
                                                    bold="700"
                                                    size="14px"
                                                    color="#878787"
                                                    margin="0 0 .4rem 0"
                                                >
                                                    {t("Name")}:
                                                    <span
                                                        style={{ fontWeight: "400", marginLeft: "1rem" }}
                                                    >
                                                        {orderData.receiver}
                                                    </span>
                                                </Paragraph>
                                                <Paragraph
                                                    bold="700"
                                                    size="14px"
                                                    color="#878787"
                                                    margin="0 0 .4rem 0"
                                                >
                                                    {t("Contact Number")}
                                                    <span
                                                        style={{ fontWeight: "400", marginLeft: "1rem" }}
                                                    >
                                                        {orderData.phone}
                                                    </span>
                                                </Paragraph>
                                                <Paragraph
                                                    bold="700"
                                                    size="14px"
                                                    color="#878787"
                                                    margin="0 0 .4rem 0"
                                                >
                                                    {t("Email")}
                                                    <span
                                                        style={{ fontWeight: "400", marginLeft: "1rem" }}
                                                    >
                                                        {orderData.email}
                                                    </span>
                                                </Paragraph>
                                                <br />
                                                <Paragraph bold="700" size="14px" color="#878787">
                                                    {t("Invoicing and Shipping Address")}:
                                                </Paragraph>
                                                <Paragraph size="14px" color="#878787">
                                                    {orderData.address}, <br />
                                                    {orderData.city}, {orderData.postcode}, <br />{" "}
                                                    {orderData.state}, {orderData.country}
                                                </Paragraph>
                                                <br />
                                                <br />
                                                <Paragraph
                                                    bold="700"
                                                    size="20px"
                                                    color="#878787"
                                                    margin="0 0 1rem 0"
                                                >
                                                    {t("Product List")}
                                                </Paragraph>

                                                <TableContainer>
                                                    <Table>
                                                        <TableHead className={classes.theHead}>
                                                            <TableRow>
                                                                <TableCell>{t("Product")}</TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {t("Price")}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {t("Quantity")}
                                                                </TableCell>
                                                                <TableCell sx={{ textAlign: "center" }}>
                                                                    {t("Total")}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {itemData.map((item) => (
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <div
                                                                            style={{ display: "flex" }}
                                                                            className={classes.theWidth}
                                                                        >
                                                                            {/* <div className={classes.theIMG}>
																				<img src={item.image} />
																			</div> */}
                                                                            <div>
                                                                                <Paragraph
                                                                                    margin="0 0 .2rem 0"
                                                                                    bold="700"
                                                                                    size="14px"
                                                                                    className="theEllipsis"
                                                                                >
                                                                                    {item.name}
                                                                                </Paragraph>
                                                                            </div>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div className={classes.theWidth2}>
                                                                            <Paragraph center>
                                                                            MYR {item.price}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div>
                                                                            <Paragraph center>
                                                                                {item.quantity}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div className={classes.theWidth2}>
                                                                            <Paragraph center>
                                                                                MYR{" "}
                                                                                {parseFloat(item.total_price).toFixed(
                                                                                    2
                                                                                )}
                                                                            </Paragraph>
                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                <br />
                                                <br />
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12} sm={12} md={8}></Grid>
                                                    <Grid item xs={12} sm={12} md={4}>
                                                        <div
                                                            style={
                                                                {
                                                                    // padding: "1rem",
                                                                    // borderRadius: "6px",
                                                                    // border: "1px solid #d7d7d7",
                                                                }
                                                            }
                                                        >
                                                            <div>
                                                                {/* <Paragraph
                      size="22px"
                      color="#878787"
                      bold="600"
                      // center
                      margin="0 0 1rem 0"
                    >
                      Order Summary
                    </Paragraph> */}
                                                                {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "1rem",
                          paddingTop: "1rem",
                        }}
                      >
                        <Paragraph color="#878787" bold="600">
                          Subtotal
                        </Paragraph>
                        <Paragraph color="#878787" bold="500">
                          RM 0000.00
                        </Paragraph>
                      </div> */}
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        marginBottom: "1rem",
                                                                        paddingTop: "1rem",
                                                                        // borderTop: "1px solid #d7d7d7",
                                                                    }}
                                                                >
                                                                    <Paragraph color="#878787" bold="600">
                                                                        {t("Shipping Fee")}
                                                                    </Paragraph>
                                                                    <Paragraph color="#878787" bold="500">
                                                                        MYR {orderData.shipping}
                                                                    </Paragraph>

                                                                </div>

                                                                {orderData.discount > 0 && (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "space-between",
                                                                            alignItems: "center",
                                                                            marginBottom: "1rem",
                                                                            paddingTop: "1rem",
                                                                            // borderTop: "1px solid #d7d7d7",
                                                                        }}
                                                                    >
                                                                        <Paragraph color="#878787" bold="600">
                                                                            {t("Discount")}
                                                                        </Paragraph>
                                                                        <Paragraph color="#878787" bold="500">
                                                                            -MYR {orderData.discount}
                                                                        </Paragraph>

                                                                    </div>

                                                                )}
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                        marginBottom: "1rem",
                                                                        paddingTop: ".4rem",
                                                                        // borderTop: "1px solid #d7d7d7",
                                                                    }}
                                                                >
                                                                    <Paragraph color="#878787" bold="600">
                                                                        {t("Total")}
                                                                    </Paragraph>
                                                                    <Paragraph color="#878787" bold="500">
                                                                        MYR {orderData.total_price}
                                                                    </Paragraph>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <br />
                                                <br />
                                                {/* <Paragraph
                bold="700"
                size="20px"
                color="#878787"
                margin="0 0 1rem 0"
              >
                Payment Type
              </Paragraph>
              <div style={{ borderBottom: "1px solid #d7d7d7" }} />
              <Paragraph bold="700" color="#878787" margin="1rem 0">
                C.O.D
              </Paragraph> */}
                                            </div>
                                        </Card>

                                        <br />
                                        <br />
                                        {/* {orderData.status_id == 1 && (
              <>
                <FormInput
                  label="Upload Consignment"
                  border="1px solid #eaeaea"
                  placeholder="Upload Consignment"
                  type="file"
                  onChange={handleChangeUpload}
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleUpload()}
                />
              </>
            )}
            {orderData.status_id == 4 && (
              <>
                <FormInput
                  label="Description"
                  border="1px solid #eaeaea"
                  placeholder="Description"
                  multiline
                  rows="3"
                  onChange={handleChange}
                  name="description"
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleSave()}
                />
              </>
            )}
            {orderData.status_id == 5 && (
              <>
                <FormInput
                  label="Description"
                  border="1px solid #eaeaea"
                  placeholder="Description"
                  multiline
                  rows="3"
                  onChange={handleChange}
                  name="description"
                />
                <Button
                  label="SUBMIT"
                  color="#fff"
                  bg="#353535"
                  bold="600"
                  // br="6px"
                  onClick={() => handleReturn()}
                />
              </>
            )} */}
                                    </div>
                                </Container>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Layout>
        </React.Fragment>
    );
}
