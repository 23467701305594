import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png"

const config = {
  theCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #707070",
    marginRight: "1rem",
  },
  theCircle_: {
    width: "45px",
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #707070",
    marginRight: "1rem",
  },
  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default makeStyles(config);
