import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import homebannerx from "../../assets/images/homebannerx.png";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png";

const config = {
  root: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...mq("sm", {
          right: 100,
        }),

        ...mq("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...mq("sm", {
          left: 100,
        }),

        ...mq("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      //   bottom: "-25px",
      bottom: "10px",
      ...mq("sm", {
        bottom: "30px",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 10,
          height: 10,
          borderRadius: "50px",
          backgroundColor: "#BCBCBC",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 10,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 30,
            backgroundColor: "#707070",
          },
        },
      },
    },
  },

  root2: {
    position: "relative",
    "& .slick-track": {
      display: "flex",
      flexDirection: "row",
      alignItems: "inherit",
    },
    "& .slick-slide": {
      height: "inherit",
      "& > div": {
        height: "100%",
        display: "flex",
      },
      "& > div > div": {
        height: "100%",
      },
    },
    "& .slick-arrow": {
      color: "#000",
      display: "flex!important",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 2,

      "&:before": {
        content: "",
        // content: "\f053",
        display: "block",
        width: 24,
        height: 24,
        // backgroundPosition: "center center",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "contain",
      },

      "&.slick-next": {
        right: 13,

        ...mq("sm", {
          right: 100,
        }),

        ...mq("md", {
          right: 100,
        }),

        "&:before": {
          content: "",
          // content: "\f054",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },

      "&.slick-prev": {
        left: 8,

        ...mq("sm", {
          left: 100,
        }),

        ...mq("md", {
          left: 100,
        }),

        "&:before": {
          content: "",
          //   content: "\f053",
          fontFamily: "Font Awesome 5 Free",
          fontWeight: 900,
          color: "#fff",
          fontSize: "2rem",
        },
      },
      "&.slick-disabled": {
        opacity: 1,
      },
    },
    "& .slick-dots": {
      position: "absolute",
      width: "100%",
      //   bottom: "-25px",
      bottom: "-22px",

      ...mq("md", {
        bottom: "10px",
        width: "auto",
      }),
      "& li": {
        margin: "0 5px",
        width: "auto",
        height: "auto",

        "& button": {
          width: 20,
          height: 5,
          borderRadius: "50px",
          backgroundColor: "#FFF",
          //   border: "1px solid #c5c5c5",
          padding: 0,
          transition: "all 0.4s",

          "@media screen and (min-width: 768px)": {
            height: 5,
          },

          "&:before": {
            display: "none",
          },
        },

        "&.slick-active": {
          "& button": {
            width: 45,
            backgroundColor: "#707070",
          },
        },
      },
    },
  },

  theComingBG: {
    position: "relative",
    backgroundImage: "url(" + homebannerx + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theCardTimer: {
    boxShadow: "0px 0px 9px 3px rgba(0,0,0,0.1)",
    position: "relative",
    padding: "1rem",
    background: "#FFF",
    borderRadius: "4px",
  },

  theBanner: {
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      height: "500px",
      objectFit: "cover",

      ...mq("sm", {
        height: "auto",
      }),

      ...mq("md", {
        height: "auto",
        objectFit: "unset",
      }),
    },
  },

  theOverlayBox: {
    position: "absolute",
    padding: "0 2rem",
    bottom: "10%",
    // transform: "translateY(-50%)",

    ...mq("sm", {
      width: "50%",
      padding: "0 4rem",
    }),

    ...mq("xmd", {
      width: "40%",
      padding: "0 6rem",
    }),
  },

  theBtnSizing: {
    position: "relative",
    width: "50%",

    ...mq("md", {
      width: "40%",
    }),
  },

  theIngBox: {
    position: "relative",
    width: "70%",
    margin: "0 auto",
    // marginBottom: "1rem",
  },

  theIngIMG: {
    position: "relative",
    width: "80%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  theTextBox: {
    position: "relative",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    ...mq("md", {
      width: "50%",
    }),
  },

  theTextBoxInner: {
    position: "relative",
    padding: "0 2rem",
    width: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    margin: "1rem 0",

    ...mq("md", {
      padding: "0 4rem",
      marginBottom: 0,
      // width: "50%",
    }),
  },

  theTitle: {
    fontSize: "18px",
    ...mq("md", {
      fontSize: "28px",
    }),
  },

  thePrice: {
    fontSize: "38px",
    ...mq("md", {
      fontSize: "48px",
    }),
  },

  theDetail: {
    fontSize: "17px",
    ...mq("md", {
      fontSize: "18px",
    }),
  },

  theIMG: {
    position: "relative",
    width: "100%",
    marginBottom: "1rem",

    ...mq("md", {
      width: "50%",
      marginBottom: 0,
    }),

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theIMG_: {
    position: "relative",
    width: "100%",

    ...mq("md", {
      width: "50%",
    }),

    "& img": {
      width: "100%",
      display: "flex",

      ...mq("sm", {
        height: "401px",
        objectFit: "cover",
      }),

      ...mq("md", {
        height: "358px",
        objectFit: "cover",
      }),

      ...mq("xmd", {
        height: "auto",
        objectFit: "unset",
      }),
    },
  },

  theIcon: {
    position: "relative",
    width: "15%",
    marginRight: "2rem",
    "& img": {
      width: "100%",
    },
  },

  theIMG2: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theIcon2: {
    position: "relative",
    width: "6%",
    marginRight: "1rem",

    "& img": {
      width: "100%",
      display: "flex",
    },
  },

  theCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
    marginRight: "1rem",
  },

  theCircle_: {
    width: "45px",
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#FFF",
    marginRight: "1rem",
  },

  theBG: {
    position: "relative",
    backgroundImage: "url(" + home005 + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  },

  theBox: {
    position: "relative",
    width: "100%",
    ...mq("md", {
      width: "50%",
      margin: "0 auto",
    }),
  },

  theLogo: {
    position: "relative",
    width: "50%",
    margin: "0 auto",
    "& img": {
      width: "100%",
    },
  },

  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  _theFontTitle: {
    fontSize: "20px",
    ...mq("md", {
     fontSize: "25px"
    }),
  }
};

export default makeStyles(config);
