import React from "react";
import { Container } from "@mui/material";
import { SectionContainer } from "./styles";

const Layout = ({ children, pd, ...others }) => {
  return (
    <Container>
      <SectionContainer {...others} pd={pd}>
        {children}
      </SectionContainer>
    </Container>
  );
};

export default Layout;
