import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import home005 from "../../assets/images/home005.png";
import pattern from "../../assets/images/pattern.png";

const config = {
  theCircle: {
    width: "40px",
    height: "40px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #707070",
    marginRight: "1rem",
  },
  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  theNewsIMG: {
    position: "relative",
    width: "100%",

    "& img": {
      width: "100%",
    },
  },

  theGalleryList: {
    position: "relative",
    display: "flex",
    overflow: "auto",
    whiteSpace: "nowrap",


    "&::-webkit-scrollbar":{
      "webkitAppearance": "none",
      "width": "2px !important"
    },
    "&::-webkit-scrollbar-thumb": {
      "borderRadius": "0px",
      "backgroundColor": "rgba(0, 0, 0, .1)",
      "boxShadow": "0 0 1px rgba(255, 255, 255, .5)"
    }

  },
  theGallery: {
    position: "relative",
    marginRight: "1rem",
    cursor: "pointer",
    "& img": {
      width: "100px",
      height: "100px",
      objectFit: "contain"
    },
  },
};

export default makeStyles(config);
