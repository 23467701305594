import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import FormInputSelect from "../../components/TextField/FormInputSelect/FormInputSelect";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Close, Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import reward001 from "../../assets/images/reward001.png";
import { useTranslation } from "react-i18next";
import { get_point_list } from "../../API/API";

export default function Membership() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t } = useTranslation();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // POINT LIST
  const [pointList, setPointList] = React.useState([]);
  const [profileData, setProfileData] = React.useState({});

  React.useEffect(() => {
    getProfileData();
    getPointList();
  }, []);
  const getProfileData = async () => {
    var res = await getProfileData();
    if (res.status) {
      setProfileData(res.data);
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };

  const getPointList = async () => {
    // pointhistory({ token: token }).then((json) => {
    //   setPointList(json.data);
    // });

    var res = await get_point_list();
    if (res.status) {
      setPointList(res.data);
    }
  };

  React.useEffect(() => {
    getPointList();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a className={clsx(classes.theNavTab)} href="/dashboard">
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                <a
                  className={clsx(classes.theNavTab, classes.theActive)}
                  href="/membership"
                >
                  {t("My Membership")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>
                {/* <a className={clsx(classes.theNavTab)} href="/address">
                  {t("Address Book")}
                </a> */}
                <a className={clsx(classes.theNavTab)} href="/referral">
                  {t("My Referral")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              {/* <Paragraph
                color="#194234"
                size="28px"
                bold="700"
                margin="0 0 1rem 0"
              >
                Welcome {profileData.name}!
              </Paragraph> */}
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("MY POINT HISTORY")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t("Date")}</TableCell>
                          <TableCell>{t("Description")}</TableCell>
                          <TableCell>{t("Points")}</TableCell>
                          <TableCell>{t("Balance")}</TableCell>
                          <TableCell>{t("Store")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pointList.map((r) => (
                          <TableRow>
                            <TableCell>{r.created_date}</TableCell>
                            <TableCell>{r.remarks}</TableCell>
                            <TableCell>
                              {r.debit != ""
                                ? "+" + r.point_in
                                : "-" + r.point_out}
                            </TableCell>
                            <TableCell>{r.balance}</TableCell>
                            <TableCell>{r.merchant_name}</TableCell>
                          </TableRow>
                        ))}
                        {/* <TableRow>
                          <TableCell>08/02/2023</TableCell>
                          <TableCell>Claim Voucher</TableCell>
                          <TableCell>- 000</TableCell>
                        </TableRow> */}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
