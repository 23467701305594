import styled from "styled-components";
import { Menu } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { mq } from "../../../styles/responsive";

export const MenuIcon = styled(Menu)`
  color: ${({ color }) => color};
`;

export const NavbarContainer = styled.div`
  background: #194234;
  position: ${(props) => (props.position ? props.position : "relative")};
  width: 100%;
  z-index: 1;
  .MuiPaper-root {
    background-color: transparent;
  }
`;

export const NavbarList = styled.div`
  position: relative;
  width: 100%;
`;

export const NavbarLogoLefted = styled.div`
  display: flex;
  // margin-right: 2rem;
`;

export const NavbarLogo = styled.a`
  position: relative;
  width: 120px;
  display: block;
  img {
    width: 120px;
  }
`;

export const BadgeCount = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #000;
  background: ${({ bg }) => bg};
  font-size: 12px;
`;

export const NavbarLower = styled.div`
  justify-content: flex-start;
  display: flex;
  padding: 1rem 0;
  flex-grow: 1;
`;

export const NavbarLowerList = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin: 0 auto;
  width: ${({ wx }) => wx};
`;

export const NavbarLink = styled.a`
  align-items: center;
  display: flex;
  position: relative;
  color: ${({ color }) => color};
  // font-size: .9rem;
  font-weight: ${({ bold }) => bold};
  // padding: 0 1rem;
  text-align: center;
  // &:last-child {
  //   border-right: unset;
  // }
`;

export const NavbarIconLink = styled.a`
  position: relative;
  display: flex;
  align-items: center;
`;

// MOBILE STYLE

export const NavbarMobileLogo = styled.a`
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
  // margin: 0 0 0 20vw;

  @media screen and (min-width: 768px) {
    // margin: 0 0 0 24vw;
  }
  img {
    width: 70px;
    margin: 0.8rem 0.2rem;

    @media screen and (min-width: 992px) {
      width: 100px;
    }
  }
`;

export const NavbarIconMobileList = styled.div`
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 0;
  list-style: none;
  align-items: center;
  display: flex;
  // justify-content: end;
  // width: 100%;

  .MuiListItem-root {
    display: flex;
    @media screen and (min-width: 992px) {
      display: block;
    }
  }
`;

export const NavbarMobieLinkList = styled.div`
  position: relative;
  width: 100%;
  display: block;

  .MuiMenuItem-root {
    display: block;
  }
  // .MuiListItem-button:hover {
  //   background-color: #7f222c !important;
  // }
`;

const config = {
  theFontSizing: {
    ...mq("md", {
      fontSize: "12px",
      padding: "0 .5rem",
    }),
    ...mq("xmd", {
      fontSize: "14px",
      padding: "0 1rem",
    }),
  },

  theBtnRegister: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    background:"#4FCEA1",
    borderRadius: "8px",
    ...mq("md", {
      fontSize: "12px",
      padding: "0.2rem .4rem",
    }),
    ...mq("xmd", {
      fontSize: "14px",
      padding: "0.4rem .4rem",
    }),
  },

  theBtnLogin: {
    display: "flex",
    alignItems: "center",
    color: "#4FCEA1",
    border:"1px solid #4FCEA1",
    background: "#fff",
    borderRadius: "8px",
    margin:"0 1rem 0 .8rem",
    ...mq("md", {
      fontSize: "12px",
      padding: "0.2rem .4rem",
    }),
    ...mq("xmd", {
      fontSize: "14px",
      padding: "0.4rem .4rem",
    }),
  },
};

export default makeStyles(config);
