import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";
import bglogin from "../../assets/images/bglogin.png";

const config = {
  theBG: {
    position: "relative",
    backgroundImage: "url(" + bglogin + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
  },
  theBox: {
    position: "relative",
    width: "auto",
    margin: "0 auto",

    ...mq("md", {
      width: "60%",
    }),
  },
  theBtnSizing: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...mq("md", {
      width: "45%",
    }),
  },
};

export default makeStyles(config);
