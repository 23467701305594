import React, { useRef } from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Card, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { get_news_details } from "../../API/API";
import reward from "../../assets/images/reward.png";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

export default function NewsDetail() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const params = useParams();
  const slider1 = useRef();
  const slider2 = useRef();
  const [tabsPhoto, setTabsPhoto] = React.useState(0);

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };

  const [newsInfo, setNewsInfo] = React.useState(null);

  const get_data = async () => {
    var news_id = params.news_id;
    var res = await get_news_details({ news_id: news_id });

    if (res.status) {
      if (res.data.images.length > 0) {
        setTabsPhoto(res.data.images[0].image);
      }
      // setNewsInfo(res.data);
      setNewsInfo({
        ...res.data,
        title: {
          en: res.data.title,
          cn: res.data.title_cn,
          inr: res.data.title_inr,
          my: res.data.title_bm,
        },
        content: {
          en: res.data.content,
          cn: res.data.content_cn,
          inr: res.data.content_inr,
          my: res.data.content_bm,
        },
      });
    }
  };

  React.useEffect(() => {
    get_data();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("News")}
          </Paragraph>
        </Layout>
      </div>
      {newsInfo && (
        <Layout>
          <div className={classes.theNewsIMG}>
            <Slider 
            style={{
              marginBottom: 30
            }}
            ref={slider1}
              dots={true}
              autoplay={true}
              autoplaySpeed={3000}
            >
              {newsInfo.images[i18n.language].map((r) => (
                <img src={r.image} 
                />
              ))}
            </Slider>
     
          </div>

          <Box sx={{ padding: ".4rem 1rem" }}>
            <Paragraph size="24px" color="#000" bold="700" margin="0 0 1rem 0">
              {newsInfo.title[i18n.language]}
              {/* {newsInfo.title} */}
            </Paragraph>
            <div
              dangerouslySetInnerHTML={{
                __html: newsInfo.content[i18n.language],
              }}
            // dangerouslySetInnerHTML={{
            //   __html: newsInfo.content,
            // }}
            />
          </Box>
        </Layout>
      )}

      <Footer />
    </React.Fragment>
  );
}
