import React from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Card, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { get_videos } from "../../API/API";
import reward from "../../assets/images/reward.png";
import Slider from "react-slick";

export default function Video() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [video, setVideo] = React.useState([]);

  const get_data = async () => {
    var res = await get_videos();

    if (res.status) {
      var _video = [...res.data];
      for (var i = 0; i < _video.length; i++) {
        _video[i]["title"] = {
          en: _video[i].title,
          cn: _video[i].title_cn,
          my: _video[i].title_bm,
          inr: _video[i].title_inr
        };
        
        // _video[i]["content"] = {
        //   en: _video[i].content,
        //   cn: _video[i].content_cn,
        //   my: _video[i].content_bm,
        // };
        _video[i]["image"] = {
          en: _video[i].image,
          cn: _video[i].image_cn,
          my: _video[i].image_bm,
          inr: _video[i].image_inr
        };
        // var r = _video[i];
        // _video.push({
        //   video_id: r.video_id,
        //   image: r.image,
        //   created_date: r.created_date,
        //   title: {
        //     en: r.title,
        //     cn: r.title_cn,
        //     my: r.title_bm,
        //   },
        //   short_description: {
        //     en: r.short_description_en,
        //     cn: r.short_description_cn,
        //     bm: r.short_description_bm,
        //   },
        // });
      }
      setVideo(_video);
    }
  };

  React.useEffect(() => {
    get_data();
  }, []);

  const iframeRef = React.useRef(null);
  const defaultHeight = 495;
  const [videoHeight, setVideoHeight] = React.useState(
    iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
  );

  const handleChangeVideoWidth = React.useCallback(() => {
    const ratio =
      window.innerWidth > 990
        ? 1.0
        : window.innerWidth > 522
        ? 1.2
        : window.innerWidth > 400
        ? 1.45
        : 1.85;
    const height = iframeRef.current
      ? iframeRef.current.offsetWidth * 0.5625
      : defaultHeight;
    setVideoHeight(Math.floor(height * ratio));
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box>
          <NavbarLefted />
          <div
            style={{
              // background: "#194234",
              height: "250px",
              display: "flex",
              alignItems: "center",
            }}
            className={classes.thePattern}
          >
            <Layout>
              <Paragraph
                color="#FFF"
                size="32px"
                bold="700"
                margin="0 0 1rem 0"
                center
              >
                {t("Video")}
              </Paragraph>
            </Layout>
          </div>
        </Box>
        <Box>
          <Layout>
            <Box>
              <Grid container spacing={3}>
                {video.map((r) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <a href={`../videodetail/${r.video_id}`}>
                      <Card shadow pd="2rem">
                        <div className={classes.theNewsIMG}>
                          <img src={r.image[i18n.language]} />
                          {/* <iframe
                            ref={iframeRef}
                            width="100%"
                            height={`${videoHeight}px`}
                            src={r.image[i18n.language]}
                            allowFullScreen
                            style={{ border: 0 }}
                          ></iframe> */}
                        </div>
                        <Box sx={{ padding: ".4rem 1rem" }}>
                          <a href={`../videodetail/${r.video_id}`}>
                            <Paragraph
                              color="#000"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              {r.title[i18n.language]}
                            </Paragraph>
                            {/* <Paragraph
                              className="theTextDots"
                              size="14px"
                              margin="0 0 .4rem 0"
                            >
                              {r.content[i18n.language]}
                            </Paragraph> */}
                            <Paragraph
                              className="theTextDots"
                              size="14px"
                              margin="0 0 .4rem 0"
                            >
                              {r.created_date}
                            </Paragraph>
                          </a>
                        </Box>
                      </Card>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Layout>
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
}
