import React from "react";
import useStyles, { CircleCount } from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Grid } from "@mui/material";
import Card from "../../components/Card/Card";
import reward from "../../assets/images/reward.png";
import merchant001 from "../../assets/images/merchant001.png";
import { MonetizationOn } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { get_partners, join_merchant } from "../../API/API";
// import i18n from "../../hooks/useLang";

export default function Join() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [partners, setPartners] = React.useState([]);
  // JOIN
  const [joinData, setJoinData] = React.useState({
    name: "",
    located: "",
    contact: "",
    email: "",
    website: "",
    business_type: "",
  });
  const handleChange = (e) => {
    setJoinData({
      ...joinData,
      [e.target.name]: e.target.value,
    });
  };

  const get_data = async () => {
    var res = await get_partners();
    // if (res.status) {
    //   setPartners(res.data);
    // }
    if (res.status) {
      const sortedPartners = res.data.sort((a, b) =>
        a.title.localeCompare(b.title)
      );
      setPartners(sortedPartners);
    }
  };
  const handleSubmit = () => {
    if (
      !joinData.name ||
      !joinData.located ||
      !joinData.contact ||
      !joinData.email ||
      !joinData.website ||
      !joinData.business_type
    ) {
      alert("All Field Required");
      return;
    }
    join_merchant(joinData).then((json) => {
      if (json.status) {
        alert("Submit Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  React.useEffect(() => {
    get_data();
  }, []);
  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("Join Merchants")}
          </Paragraph>
          {/* <Paragraph color="#FFF" size="14px" margin="0 0 1rem 0" center>
            {t("Get rewards on each box purchased")}
          </Paragraph> */}
        </Layout>
      </div>
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#194234"
                  size="24px"
                  bold="700"
                  margin="0 0 1rem 0"
                >
                  {t("How it works ?")}
                </Paragraph>

                <br />
                <br />

                <div style={{ display: "flex" }}>
                  <CircleCount bg="#1a372d">1</CircleCount>
                  <Paragraph style={{ width: "80%" }} color="#424242">
                    {t("Purchase any amount of Royal Bitter Gourd Peptide")}
                  </Paragraph>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <CircleCount bg="#3c7965">2</CircleCount>
                  <Paragraph style={{ width: "80%" }} color="#424242">
                    {t("Go to My Membership to claim your voucher")}
                  </Paragraph>
                </div>
                <br />
                <div style={{ display: "flex" }}>
                  <CircleCount bg="#4dae8d">3</CircleCount>
                  <Paragraph style={{ width: "80%" }} color="#424242">
                    {t(
                      "Show the QR code of your voucher to the staff to scan and enter the valid digits to active the voucher"
                    )}
                  </Paragraph>
                </div>
                <br />

                <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                  <Button
                    // href="#form"
                    color="#FFF"
                    bg="#194234"
                    label={t("Join Merchants")}
                    br="4px"
                    pd=".6rem 1rem"
                    onClick={(e) => {
                      e.preventDefault();
                      window.scrollTo({
                        top: document.querySelector("#form").offsetTop,
                        behavior: "smooth",
                      });
                    }}
                  />
                </Box>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.theRewardIMG}>
                <img src={reward} />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Box sx={{ background: "#F2F2F2" }}>
        <Layout>
          <Paragraph
            color="#194234"
            size="32px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            {t("Participating Merchants")}
          </Paragraph>
          <Grid container spacing={3}>
            {/* {partners.map((r) => (
              <Grid item xs={12} sm={6} md={3}>
                <a href={r.link} target="_blank">
                  <Card bg="#FFF" br="10px" pd="0" shadow>
                    <div className={classes.theMerchantIMG}>
                      <img src={r.image} />
                    </div>
                    <div
                      style={{
                        background: "#194234",
                        padding: ".6rem",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      }}
                    >
                      <Paragraph center color="#FFF" bold="500">
                        {r.title}
                      </Paragraph>
                      <Paragraph
                        center
                        color="#FFF"
                        style={{
                          fontSize: "0.8rem",
                        }}
                      >
                        {r.short_description}
                      </Paragraph>
                    </div>
                  </Card>
                </a>
              </Grid>
            ))} */}
            {partners
              .slice()
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((r) => (
                <Grid item xs={12} sm={6} md={3} key={r.title}>
                  <a href={r.link} target="_blank">
                    <Card bg="#FFF" br="10px" pd="0" shadow>
                      <div className={classes.theMerchantIMG}>
                        <img src={r.image} alt={r.title} />
                      </div>
                      <div
                        style={{
                          background: "#194234",
                          padding: ".6rem",
                          borderBottomLeftRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                      >
                        <Paragraph center color="#FFF" bold="500">
                          {r.title}
                        </Paragraph>
                        <Paragraph
                          center
                          color="#FFF"
                          style={{
                            fontSize: "0.8rem",
                          }}
                        >
                          {r.short_description}
                        </Paragraph>
                      </div>
                    </Card>
                  </a>
                </Grid>
              ))}
          </Grid>
        </Layout>
      </Box>
      <Box className={classes.thePattern} id="form">
        <Layout>
          <Grid container spacing={3} sx={{ alignItems: " center" }}>
            <Grid item xs={12} sm={12} md={6}>
              <div>
                <Paragraph
                  color="#FFF"
                  size="32px"
                  bold="700"
                  margin="0 0 1rem 0"
                >
                  {t("Join Our Merchant Program")}
                </Paragraph>

                <Paragraph color="#FFF" size="16px">
                  {t(
                    "By joining our merchant program, you'll enjoy increased visibility and exposure to potential customers, customer can enjoyed exclusive discounts or pricing on your shop and marketing and promotional support to help increase sales and grow your business"
                  )}
                </Paragraph>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Card bg="#FFF" pd="2rem">
                <Paragraph color="#707070" size="24px" margin="0 0 1rem 0">
                  {t("Join Merchants")}
                </Paragraph>
                <FormInput
                  label={t("Company Name")}
                  placeholder={t("Company Name")}
                  name="name"
                  required
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Company Located")}
                  placeholder={t("Company Located")}
                  name="located"
                  required
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Contact Number")}
                  placeholder={t("Contact Number")}
                  name="contact"
                  required
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Email")}
                  placeholder={t("Email")}
                  name="email"
                  required
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Company Website")}
                  placeholder={t("Company Website")}
                  name="website"
                  required
                  onChange={handleChange}
                />
                <FormInput
                  label={t("Business Type")}
                  placeholder={t("Business Type")}
                  name="business_type"
                  required
                  onChange={handleChange}
                />
                <br />
                <Button
                  color="#FFF"
                  bg="#4FCEA1"
                  label={t("SEND")}
                  size="14px"
                  br="4px"
                  pd=".6rem 1rem"
                  onClick={() => handleSubmit()}
                />
              </Card>
            </Grid>
          </Grid>
        </Layout>
      </Box>

      <Footer />
    </React.Fragment>
  );
}
