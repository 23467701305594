import React, { useState } from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Grid, Button as ButtonMui } from "@mui/material";
import { Add, ArrowForwardIos, NotificationsActive, Remove } from "@mui/icons-material";

import prod001 from "../../assets/images/prod001.png";
import prod002 from "../../assets/images/prod002.png";
import prod003 from "../../assets/images/prod003.png";
import prod004 from "../../assets/images/prod004.png";
import prodicon001 from "../../assets/images/prodicon001.svg";
import prodicon002 from "../../assets/images/prodicon002.svg";
import Slider from "react-slick";
import useCart from "../../hooks/useCart";
import { useNavigate } from "react-router-dom";
import { get_product, subscribe } from "../../API/API";
import { useTranslation } from "react-i18next";

export default function Product() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // SLLIDER SETTING

  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // COUNT QUANTITY
  const [count, setCount] = useState(1);

  const handleChangeQty = (new_qty) => {
    if (new_qty < 1) {
      return;
    }
    setCount(new_qty);
  };

  const [product, setProduct] = React.useState(null);

  // HANDLE ADD TO CART
  const { addProduct, getCart } = useCart();

  const [cart, setCart] = React.useState(getCart);

  React.useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  const [tabsPhoto, setTabsPhoto] = React.useState(0);

  const handleChangeTabPhoto = (text) => {
    setTabsPhoto(text);
  };

  const handleAddToCart = () => {
    var price = product.price;

    if (count >= 500) {
      price = product.price_500;
    } else if (count >= 200) {
      price = product.price_200;
    } else if (count >= 100) {
      price = product.price_100;
    } else if (count >= 50) {
      price = product.price_50;
    } else {
      price = product.price;
    }
    let arr = {
      name: product.name,
      price: price,
      quantity: count,
      images: product.image,
      product_id: product.item_id,
    };
    addProduct(arr);

    navigate("/payment");
  };

  const get_data = async () => {
    var res = await get_product();
    if (res.status) {
      setTabsPhoto(res.data.thumbs[0].image);
      setProduct({
        ...res.data,
        name: {
          en: res.data.name,
          cn: res.data.name_cn,
          inr: res.data.name_inr,
          my: res.data.name_bm,
        },
        images: {
          en: res.data.images,
          cn: res.data.images_cn,
          inr: res.data.images_inr,
          my: res.data.images_bm,
        },
        description: {
          en: res.data.description,
          cn: res.data.description_cn,
          inr: res.data.description_inr,
          my: res.data.description_bm,
        },
        short_description: {
          en: res.data.short_description,
          my: res.data.short_description_bm,
          cn: res.data.short_description_cn,
          inr: res.data.short_description_inr,
        },
      });
    }
  };
  // SUBSCRIBE
  const [subscribeData, setSubscribeData] = React.useState({
    email: "",
  });

  const handleSubscribe = () => {
    if (!subscribeData.email) {
      alert("All Field Required");
      return;
    }

    subscribe(subscribeData).then((json) => {
      if (json.status) {
        alert("Subscribed Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  React.useEffect(() => {
    get_data();
  }, []);
  return (
    <>
      <React.Fragment>
        <NavbarLefted />

        {product && (
          <>
            <Layout>
              <Box>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <Paragraph size="14px" color="#707070">
                    {t("Home")}
                  </Paragraph>
                  <ArrowForwardIos
                    sx={{
                      color: "#707070",
                      fontSize: "12px",
                      margin: "0 .4rem",
                    }}
                  />
                  <Paragraph size="14px" color="#707070">
                    {product.name[i18n.language]}
                  </Paragraph>
                </div>
                <Grid container spacing={6} sx={{ alignItems: "center" }}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.theProdIMG}>
                      {/* <img src={product.image} /> */}
                      <img src={tabsPhoto[i18n.language]} />
                    </div>
                    {product.thumbs && (
                      <div className={classes.theGalleryList}>
                        {product.thumbs.map((item) => (
                          <div
                            className={classes.theGallery}
                            onClick={() => {
                              handleChangeTabPhoto(item.image);
                            }}
                          >
                            <img src={item.image[i18n.language]} />
                          </div>
                        ))}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <div className={classes.theHeight}>
                      <div>
                        <Paragraph
                          color="#194234"
                          size="28px"
                          margin="0 0 1rem 0"
                        >
                          {product.name[i18n.language]}
                        </Paragraph>
                        <Paragraph
                          color="#194234"
                          size="18px"
                          margin="0 0 1rem 0"
                          dangerouslySetInnerHTML={{
                            __html: product.description[[i18n.language]],
                          }}
                        ></Paragraph>
                        <Paragraph
                          color="#000"
                          size="48px"
                          bold="700"
                          margin="0 0 1rem 0"
                        >
                          MYR {product.price}{" "}
                          <span style={{ fontSize: "24px" }}>{t("/box")}</span>
                        </Paragraph>
                        {/* <div dangerouslySetInnerHTML={{
                          __html: product.description[i18n.language]
                        }}></div> */}
                      </div>
                      <div>
                        <FormInput
                          label={t("Enter your quantity")}
                          textLabelColor="#707070"
                          type="number"
                          value={count}
                          size="14px"
                          onChange={(e)=>{
                            setCount(e.target.value);
                          }}
                          br="50px"
                          pd="14.5px 14px"
                          center
                          sx={{ width: { xs: "100%", md: "60%" } }}
                          InputProps={{
                            startAdornment: (
                              <Remove
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleChangeQty(count - 1)}
                              />
                            ),
                            endAdornment: (
                              <Add
                                sx={{ cursor: "pointer" }}
                                onClick={() => setCount(count + 1)}
                              />
                            ),
                          }}
                        />
                        <br />
                        <div className={classes.theBtnSizing}>
                          <Button
                            color="#FFF"
                            bg="#4FCEA1"
                            label={t("BUY NOW")}
                            size="14px"
                            br="4px"
                            pd=".6rem 3rem"
                            // href="/payment"
                            onClick={() => handleAddToCart()}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Layout>

            <Box
              sx={{
                marginTop: {
                  xs: "2rem",
                  sm: "8rem",
                  md: "16rem",
                  lg: "6rem",
                  xl: "12rem",
                },
              }}
            >
              <Layout>
                <Paragraph
                  color="#194234"
                  size="28px"
                  bold="700"
                  margin="0 0 1rem 0"
                  center
                >
                  {t("Product Detail")}
                </Paragraph>
                <Card pd="1.5rem 2rem" border="1px solid #194234">
                  {product.images[i18n.language].map((r) => (
                    <img src={r.image} style={{ width: "100%" }} />
                  ))}
                  {/* <Paragraph size="14px">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                  commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                  penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                  Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                  Nulla consequat massa quis enim. Donec pede justo, fringilla vel,
                  aliquet nec, vulputate
                </Paragraph> */}
                </Card>
                <br />
                <Card pd="1.5rem 2rem" border="1px solid #194234">
                  <Paragraph
                    color="#194234"
                    size="24px"
                    bold="700"
                    margin="0 0 1rem 0"
                  >
                    {t("Ingredients")}
                  </Paragraph>
                  <Paragraph size="14px">
                    {t(
                      "Protein > 92%, Solubility =100%, NSI Value = 100%, Bitter Gourd Peptide, Soya Protein, Polysaccharide"
                    )}
                  </Paragraph>
                </Card>
                <br />
                <Card pd="1.5rem 2rem" border="1px solid #194234">
                  <Paragraph
                    color="#194234"
                    size="24px"
                    bold="700"
                    margin="0 0 1rem 0"
                  >
                    {t("How To Use")}
                  </Paragraph>
                  <Paragraph
                    color="#194234"
                    size="18px"
                    bold="700"
                    margin="0 0 1rem 0"
                  >
                    {t("Method 1")}
                  </Paragraph>
                  <Paragraph size="14px" margin="0 0 1rem 0">
                    {t(
                      "Cool boiling water at room temperature (25-30 degrees,about 180ml) into the bowl (water at room temperature will be easier to drink)"
                    )}
                  </Paragraph>
                  <Paragraph size="14px" margin="0 0 1rem 0">
                    {t(
                      "No need to stir, the peptide will naturally dissolve automatically."
                    )}
                  </Paragraph>
                  <Paragraph size="14px" margin="0 0 1rem 0">
                    {t(
                      "You can drink it before and after meals, it will be better to eat your own health care products or medicines after drinking for 10 minutes,"
                    )}
                  </Paragraph>
                  <div
                    style={{
                      display: "flex",
                      padding: "1rem 0",
                      position: "relative",
                    }}
                  >
                    <img style={{ marginRight: "20px" }} src={prod002} />
                    <img src={prod003} />
                  </div>
                  <Paragraph
                    color="#194234"
                    size="18px"
                    bold="700"
                    margin="1rem 0"
                  >
                    {t("Method 2")}
                  </Paragraph>
                  <Paragraph size="14px" margin="0 0 1rem 0">
                    {t(
                      "If there is blood or pus in the wound, it can be poured directly to cover the wound. If the wound has no moisture, add water first and then apply wild bitter gourd small molecular peptide and rub it on the wound Very effective"
                    )}
                  </Paragraph>
                </Card>
              </Layout>
            </Box>
          </>
        )}

        <Box className={classes.thePattern}>
          <Layout>
            <div className={classes.theClient}>
              <img src={prod004} />
            </div>
          </Layout>
        </Box>
        {/* <Layout>
          <Paragraph
            color="#194234"
            size="28px"
            bold="700"
            margin="0 0 2rem 0"
            center
          >
            {t("What our customers say")}
          </Paragraph>
          <Paragraph size="14px" margin="0 0 1rem 0" center>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
            commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
            et
          </Paragraph>
          <Slider className={classes.root} {...settings}>
            <div>
              <Card pd=" 1.5rem 1rem" shadow style={{ margin: "1rem" }}>
                <div className={classes.theIcon}>
                  <img src={prodicon001} />
                </div>
                <Paragraph size="14px" margin="1rem 0" center>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                  commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                  penatibus.
                </Paragraph>

                <div className={classes.theAvatar}>
                  <img src={prodicon002} />
                </div>
                <Paragraph size="12px" margin=".4rem 0" center>
                  username
                </Paragraph>
              </Card>
            </div>
            <div>
              <Card pd=" 1.5rem 1rem" shadow style={{ margin: "1rem" }}>
                <div className={classes.theIcon}>
                  <img src={prodicon001} />
                </div>
                <Paragraph size="14px" margin="1rem 0" center>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                  commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                  penatibus.
                </Paragraph>

                <div className={classes.theAvatar}>
                  <img src={prodicon002} />
                </div>
                <Paragraph size="12px" margin=".4rem 0" center>
                  username
                </Paragraph>
              </Card>
            </div>
            <div>
              <Card pd=" 1.5rem 1rem" shadow style={{ margin: "1rem" }}>
                <div className={classes.theIcon}>
                  <img src={prodicon001} />
                </div>
                <Paragraph size="14px" margin="1rem 0" center>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                  commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                  penatibus.
                </Paragraph>

                <div className={classes.theAvatar}>
                  <img src={prodicon002} />
                </div>
                <Paragraph size="12px" margin=".4rem 0" center>
                  username
                </Paragraph>
              </Card>
            </div>
            <div>
              <Card pd=" 1.5rem 1rem" shadow style={{ margin: "1rem" }}>
                <div className={classes.theIcon}>
                  <img src={prodicon001} />
                </div>
                <Paragraph size="14px" margin="1rem 0" center>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                  commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                  penatibus.
                </Paragraph>

                <div className={classes.theAvatar}>
                  <img src={prodicon002} />
                </div>
                <Paragraph size="12px" margin=".4rem 0" center>
                  username
                </Paragraph>
              </Card>
            </div>
          </Slider>
          <br />
        </Layout> */}
        <div className={classes.theBG}>
          <Layout pd="2rem 0">
            <Box sx={{ width: { xs: "100%", md: "70%" }, margin: "0 auto" }}>
              <Paragraph color="#FFF" size="28px" margin="0 0 1rem 0" center>
                {t("Subscribe To Get Update")}
              </Paragraph>
              <Paragraph
                color="#FFF"
                size="14px"
                margin="0 0 1rem 0"
                center
              ></Paragraph>
              <br />
              <FormInputNoLabel
                placeholder={t("Your Email Address")}
                bg="#FFF"
                br="4px"
                onChange={(e) => {
                  setSubscribeData({
                    email: e.target.value
                  });
                }}
                pd="14.5px 14px"
                name="email"
                InputProps={{
                  endAdornment: (
                    <ButtonMui
                      onClick={() => handleSubscribe()}
                      color="error"
                      variant="contained"
                      style={{
                        whiteSpace: "nowrap",
                        paddingLeft: 30,
                        paddingRight: 30,
                        fontSize: "0.7rem"
                      }}
                    >
                      {t("SUBSCRIBE")}{" "}
                      <NotificationsActive
                        style={{
                          marginLeft: 6,
                        }}
                      />
                    </ButtonMui>
                    // <Button
                    //   color="#FFF"
                    //   bg="#ce4f4f"
                    //   style={{
                    //     whiteSpace: "nowrap"
                    //   }}
                    //   label={(<>t("SUBSCRIBE") <NotificationsActive /></>)}
                    //   size="14px"
                    //   br="4px"
                    //   pd=".4rem 3rem"
                    //   onClick={() => handleSubscribe()}
                    // />
                  ),
                }}
              />
            </Box>
          </Layout>
        </div>
        <Footer />
      </React.Fragment>
    </>
  );
}
