import React from "react";
import styled from "styled-components";

export const ButtonBox = styled.a`
  background: ${(props) => (props.bg ? props.bg : "#fff")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.pd ? props.pd : "0.4rem 1rem")};
  border-radius: ${(props) => (props.br ? props.br : "0")};
  border: ${(props) => (props.bd ? props.bd : "unset")};
  align-items: center;
  justify-content: center;
  display: flex;

  &:hover {
    background: ${(props) => (props.hover ? props.hover : "")};
  }
`;

export const Label = styled.p`
  color: ${(props) => (props.color ? props.color : "#000")};
  font-size: ${(props) => (props.size ? props.size : "1rem")};
  font-weight: ${(props) => (props.bold ? props.bold : "normal")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
`;
