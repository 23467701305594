import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import pattern from "../../assets/images/pattern2.png"

export const NavbarSidebarList = styled.div`
  padding: 4rem 1rem;
`;

export const NavbarSidebarListLogo = styled.a`
  justify-content: center;
  align-items: center;
  display:flex;
  img {
    width: 150px;
  }
`;

export const CloseButtonBox = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const config = {
  theSideBar: {
    width: "300px",
    background: "#00392c",
  },

  thePattern: {
    position: "relative",
    backgroundImage: "url(" + pattern + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#00392c",
    // width: "100%",
    width: "300px",
    height: "100vh",
    position: "fixed"
  },

};

export default makeStyles(config);