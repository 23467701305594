import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import reward001 from "../../assets/images/reward001.png";
import { useTranslation } from "react-i18next";
import { get_orders, get_profile } from "../../API/API";

export default function Membership() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [orders, setOrders] = React.useState([]);
  const [profileData, setProfileData] = React.useState(null);
  const getProfileData = async () => {

    var res = await get_profile();
    if (res.status) {
      setProfileData(res.data);
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };
  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  const { t } = useTranslation();

  // ORDER LIST
  const [orderList, setOrderList] = React.useState([]);

  const getOrderList = async () => {
    var res = await get_orders();
    if (res.status) {
      setOrderList(res.data);
    }
  };

  React.useEffect(() => {
    getOrderList();
    getProfileData();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a
                  className={clsx(classes.theNavTab)}
                  href="/dashboard"
                >
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                {profileData && (
                  <>
                    {
                      profileData.is_confirmed_member == 1 && (
                        <a className={clsx(classes.theNavTab)} href="/membership">
                          {t("My Membership")}
                        </a>
                      )
                    }
                  </>
                )}


                <a className={clsx(classes.theNavTab, classes.theActive)} href="/order">
                  {t("My Orders")}
                </a>

                {profileData && (
                  <>
                    {profileData.is_confirmed_member == 1 && (
                      <a className={clsx(classes.theNavTab)} href="/referral">
                        {t("My Referral")}
                      </a>
                    )}

                  </>
                )}
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>

              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{t("MY ORDERS")}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>{t("Date")}</TableCell>
                          <TableCell>{t("Amount")}</TableCell>
                          <TableCell>{t("Status")}</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                    <TableBody>
                        {orderList.map(r => (
                          <TableRow

                          >
                            <TableCell onClick={() => {
                              navigate("/order/details/" + r.orders_id)
                            }}>{r.orders_id}</TableCell>
                            <TableCell onClick={() => {
                              navigate("/order/details/" + r.orders_id)
                            }}>{r.date}</TableCell>
                            <TableCell onClick={() => {
                              navigate("/order/details/" + r.orders_id)
                            }}> MYR{r.total_price}</TableCell>
                            <TableCell onClick={() => {
                              navigate("/order/details/" + r.orders_id)
                            }}>{r.status}</TableCell>
                            <TableCell onClick={() => {
                              window.open(r.tracking_link, "_blank")
                            }}>{r.tracking_no}</TableCell>
                          </TableRow>
                        ))}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
