import React, { useEffect, useState } from "react";
import classNames from "classnames";
import useStyles, { Text } from "./styles";

const Paragraph = ({
  size,
  color,
  bold,
  center,
  margin,
  textDecoration,
  className,
  children,
  innerHTML,
  ...others
}) => {
  const classes = useStyles({ center, margin, color, bold });

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
    return () => {
      window.removeEventListener("resize", () =>
        setScreenWidth(window.innerWidth)
      );
    };
  }, []);

  let sizeCustom = size;

  switch ((true, sizeCustom)) {
    case screenWidth <= 480:
      size = "16px";
      break;

    case screenWidth <= 768:
      size = "16px";
      break;

    case screenWidth <= 992:
      size = "16px";
      break;

    case screenWidth <= 1024:
      size = "16px";
      break;

    case screenWidth <= 1440:
      size = "16px";
      break;

    case screenWidth <= 1600:
      size = "16px";
      break;

    case screenWidth <= 1920:
      size = "16px";
      break;

    case screenWidth <= 2560:
      size = "24px";
      break;
  }

  return (
    <Text
      {...others}
      size={size}
      bold={bold}
      margin={margin}
      center={center}
      color={color}
      textDecoration={textDecoration}
      className={classNames(classes.root, className)}
    >
      {children}
    </Text>
  );
};

export default Paragraph;
