import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./context/AuthContext";
import CartContextProvider from "./context/CartContext";
import { I18nextProvider, useTranslation } from "react-i18next";
import Routes from "./Routes";
import "./App.css";
import useLang from "./hooks/useLang";

function App() {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <I18nextProvider i18n={useLang}>
      <AuthContextProvider>
        <CartContextProvider>
          <BrowserRouter>
            <Routes></Routes>
          </BrowserRouter>
        </CartContextProvider>
      </AuthContextProvider>
    </I18nextProvider>
  );
}

export default App;
