import React from "react";
import classNames from "classnames";
import useStyles, { CardContainer } from "./styles";
import { Text } from "./styles";

const Card = ({ bg, pd, border, shadow, className, children, ...others }) => {
  const classes = useStyles({});

  return (
    <CardContainer
      bg={bg}
      pd={pd}
      border={border}
      shadow={shadow}
      {...others}
      className={classNames(classes.root, className)}
    >
      {children}
    </CardContainer>
  );
};

export default Card;
