import React from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Card, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { get_news } from "../../API/API";
import reward from "../../assets/images/reward.png";
import Slider from "react-slick";

export default function News() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [news, setNews] = React.useState([]);

  const get_data = async () => {
    var res = await get_news();

    if (res.status) {
      var _news = [...res.data];
      for (var i = 0; i < _news.length; i++) {
        _news[i]["title"] = {
          en: _news[i].title,
          cn: _news[i].title_cn,
          my: _news[i].title_bm,
          inr: _news[i].title_inr
        };
        _news[i]["content"] = {
          en: _news[i].content,
          cn: _news[i].content_cn,
          my: _news[i].content_bm,
          inr: _news[i].content_inr
        };
        _news[i]["image"] = {
          en: _news[i].image,
          cn: _news[i].image_cn,
          my: _news[i].image_bm,
          inr: _news[i].image_inr
        };
        // var r = _news[i];
        // _news.push({
        //   news_id: r.news_id,
        //   image: r.image,
        //   created_date: r.created_date,
        //   title: {
        //     en: r.title,
        //     cn: r.title_cn,
        //     my: r.title_bm,
        //   },
        //   short_description: {
        //     en: r.short_description_en,
        //     cn: r.short_description_cn,
        //     bm: r.short_description_bm,
        //   },
        // });
      }
      setNews(_news);
    }
  };

  React.useEffect(() => {
    get_data();
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Box>
          <NavbarLefted />
          <div
            style={{
              // background: "#194234",
              height: "250px",
              display: "flex",
              alignItems: "center",
            }}
            className={classes.thePattern}
          >
            <Layout>
              <Paragraph
                color="#FFF"
                size="32px"
                bold="700"
                margin="0 0 1rem 0"
                center
              >
                {t("News")}
              </Paragraph>
            </Layout>
          </div>
        </Box>
        <Box>
          <Layout>
            <Box>
              <Grid container spacing={3}>
                {news.map((r) => (
                  <Grid item xs={12} sm={6} md={4}>
                    <a href={`../newsdetail/${r.news_id}`}>
                      <Card shadow pd="2rem">
                        <div className={classes.theNewsIMG}>
                          <img src={r.image[i18n.language]} />
                        </div>
                        <Box sx={{ padding: ".4rem 1rem" }}>
                          <a href={`../newsdetail/${r.news_id}`}>
                            <Paragraph
                              color="#000"
                              bold="600"
                              margin="0 0 .4rem 0"
                            >
                              {r.title[i18n.language]}
                            </Paragraph>
                            <Paragraph
                              className="theTextDots"
                              size="14px"
                              margin="0 0 .4rem 0"
                            >
                              {r.content[i18n.language]}
                            </Paragraph>
                            <Paragraph
                              className="theTextDots"
                              size="14px"
                              margin="0 0 .4rem 0"
                            >
                              {r.created_date}
                            </Paragraph>
                          </a>
                        </Box>
                      </Card>
                    </a>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Layout>
          <Footer />
        </Box>
      </Box>
    </React.Fragment>
  );
}
