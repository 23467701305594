import React, { useEffect, useState } from "react";
import {
  AppBar,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SidebarMenu from "../../SidebarMenu/SidebarMenu";

import Button from "../../Button/Button";
import logo from "../../../assets/images/logo.png";

import useStyles, {
  MenuIcon,
  NavbarContainer,
  NavbarList,
  NavbarLogo,
  NavbarMobileLogo,
  NavbarIconMobileList,
  NavbarMobieLinkList,
  NavbarLower,
  NavbarLowerList,
  NavbarLink,
  NavbarIconLink,
  BadgeCount,
  NavbarLogoLefted,
} from "./styles";

import {
  AccountCircleRounded,
  Language,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import useCart from "../../../hooks/useCart";
import { get_customer_profile } from "../../../API/API";
import Paragraph from "../../Typography/Paragraph";
import FormInputSelect from "../../TextField/FormInputSelect/FormInputSelect";
import { useTranslation } from "react-i18next";
import "./styles.css";

const NavbarLefted = ({ position }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  // CHANGE LANGUAGE
  const { i18n } = useTranslation();
  const handleChangeLang = (e) => {
    // if (e.target.value == 'id') {
    //   alert("Coming soon");
    //   return;
    // }
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("lang", e.target.value);
    // window.location.reload();
  };

  // CART

  const { getCart, clearCart } = useCart();

  const getcart = JSON.stringify(getCart());

  const [cart, setCart] = useState(getCart);

  useEffect(() => {
    setCart(getCart());
  }, [getCart]);

  // SIDE MENU & SIDE CART

  const [openSideMenu, setOpenSideMenu] = useState({ left: false });

  const [openSideCart, setOpenSideCart] = useState({ right: false });

  const handleDrawerToggle = (anchor, open) => (e) => {
    if (e.type == "keydown" && (e.key == "Tab" || e.key == "Shift")) {
      return;
    }
    setOpenSideMenu({ [anchor]: open });
    setOpenSideCart({ [anchor]: open });
  };

  //

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // TOKEN

  const [token, setToken] = useState([]);

  useEffect(() => {
    let token = window.localStorage.getItem("token");
    setToken(token || "");
    console.log("CURRENT TOKEN", token);
  }, []);

  // API

  const [profileData, setProfileData] = useState({});

  const getProfile = () => {
    // get_customer_profile({ token: token }).then((json) => {
    //   if (json.status) {
    //     setProfileData(json.data);
    //   } else {
    //     alert(json.message);
    //     window.localStorage.removeItem("token");
    //     navigate("/login");
    //   }
    // });
  };

  useEffect(() => {
    getProfile();
  }, []);

  // HANDLE LOGOUT

  const handleLogout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("cart");
    navigate("/login");
  };

  // NAVBAR ITEM

  const NAVBARLIST = [
    {
      title: "HOME",
      link: "",
    },
    {
      title: "ABOUT US",
      link: "about",
    },
    {
      title: "PRODUCT",
      link: "product",
    },
    // {
    //   title: "REWARDS",
    //   link: "reward",
    // },
    // {
    //   title: "PLANS",
    //   link: "plan",
    // },
    {
      title: "CONTACT",
      link: "contact",
    },
    {
      title: "NEWS",
      link: "news",
    },
    {
      title: "VIDEOS",
      link: "videos",
    },
    // {
    //   title: "JOIN MERCHANTS",
    //   link: "join",
    // },
  ];

  return (
    <>
      <NavbarContainer position={position}>
        <AppBar position="static" sx={{ boxShadow: "unset" }}>
          <Toolbar>
            {isMobile ? (
              <>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerToggle("left", true)}
                >
                  <MenuIcon color="#FFF" />
                </IconButton>
                <NavbarMobileLogo href="/">
                  <img src={logo} />
                </NavbarMobileLogo>
                <NavbarIconMobileList>
                  {token ? (
                    <>
                      {/* <NavbarIconLink
                          href="/payment"
                          style={{ marginRight: "1.5rem" }}
                        >
                          <ShoppingCartOutlined sx={{ color: "#FFF" }} />
                          <BadgeCount>{cart.length}</BadgeCount>
                        </NavbarIconLink> */}

                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded sx={{ color: "#FFF" }} />
                      </NavbarIconLink>
                    </>
                  ) : (
                    <>
                      <NavbarIconLink onClick={handleMenu}>
                        <AccountCircleRounded sx={{ color: "#FFF" }} />
                      </NavbarIconLink>
                    </>
                  )}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "1rem",
                    }}
                  >
                    <Language
                      sx={{
                        color: "#263f39",
                      }}
                    />
                    <FormInputSelect
                      mb="0"
                      border="unset"
                      style={{
                        color: "#FFF",
                      }}
                      onChange={handleChangeLang}
                      value={i18n.language}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                    >
                      <MenuItem value="en">EN</MenuItem>

                      <MenuItem value="cn">CN</MenuItem>
                      <MenuItem value="my">MY</MenuItem>
                      <MenuItem value="inr">ID</MenuItem>
                    </FormInputSelect>
                  </div>
                </NavbarIconMobileList>

                <SidebarMenu
                  anchor="left"
                  open={openSideMenu.left}
                  onOpen={handleDrawerToggle("left", true)}
                  onClose={handleDrawerToggle("left", false)}
                  handleDrawerToggle={handleDrawerToggle("left", false)}
                />
              </>
            ) : (
              <>
                <NavbarList>
                  <NavbarLower>
                    <NavbarLowerList wx="100%">
                      <NavbarLogoLefted>
                        <NavbarLogo href="/">
                          <img src={logo} />
                        </NavbarLogo>
                      </NavbarLogoLefted>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          {NAVBARLIST.map((item) => {
                            return (
                              <NavbarLink
                                className={classes.theFontSizing}
                                key={item.title}
                                color={
                                  location.pathname.replace(
                                    /[^A-Za-z]+/g,
                                    ""
                                  ) === item.link
                                    ? "#FFF"
                                    : "#FFF"
                                }
                                bold={
                                  location.pathname.replace(
                                    /[^A-Za-z]+/g,
                                    ""
                                  ) === item.link
                                    ? "700"
                                    : "normal"
                                }
                                href={"/" + item.link}
                              >
                                {t(item.title)}
                              </NavbarLink>
                            );
                          })}
                        </div>
                        <div style={{ display: "flex" }}>
                          {token ? (
                            <>
                              {/* <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "1rem"
                                    }}
                                  >
                                    <Language
                                      sx={{
                                        color: "#263f39",
                                      }}
                                    />
                                    <FormInputSelect
                                      mb="0"
                                      border="unset"
                                      style={{
                                        color: "#FFF"
                                      }}
                                      onChange={handleChangeLang}
                                      value={i18n.language}

                                      MenuProps={{
                                        disableScrollLock: true,
                                      }}


                                    >
                                      <MenuItem value="en">EN</MenuItem>
                                      <MenuItem value="cn">CN</MenuItem>
                                      <MenuItem value="my">MY</MenuItem>
                                    </FormInputSelect>
                                  </div> */}

                              <NavbarIconLink onClick={handleMenu}>
                                <AccountCircleRounded sx={{ color: "#FFF" }} />
                              </NavbarIconLink>
                            </>
                          ) : (
                            <>
                              {/* <Button
                               className={classes.theBtnRegister}
                                color="#FFF"
                                bg="#4FCEA1"
                                label={t("REGISTER")}
                                size="14px"
                                br="50px"
                                href="/register"
                              />
                              <Button
                             
                                color="#4FCEA1"
                                bd="1px solid #4FCEA1"
                                label={t("LOGIN")}
                                size="14px"
                                br="50px"
                                margin="0 2rem 0 1rem"
                                href="/login"
                              /> */}
                              {/* <a
                                className={classes.theBtnRegister}
                                href="/register"
                              >
                                {t("REGISTER")}
                              </a> */}
                              <a className={classes.theBtnLogin} href="/login">
                                {t("LOGIN")}
                              </a>

                              {/* <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Language sx={{ color: "#263f39", marginRight: "14px" }} />
                                    <Paragraph color="#263f39">EN</Paragraph>
                                    <Paragraph color="#263f39" margin="0 1rem">
                                      |
                                    </Paragraph>
                                    <Paragraph color="#263f39">CN</Paragraph>
                                  </div> */}
                            </>
                          )}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              // marginLeft: "1rem",
                            }}
                          >
                            <Language
                              sx={{
                                color: "#FFF",
                              }}
                            />
                            <FormInputSelect
                              mb="0"
                              border="unset"
                              onChange={handleChangeLang}
                              value={i18n.language}
                              style={{
                                color: "#FFF",
                              }}
                              MenuProps={{
                                disableScrollLock: true,
                              }}
                            >
                              <MenuItem value="en">EN</MenuItem>

                              <MenuItem value="cn">CN</MenuItem>
                              <MenuItem value="my">MY</MenuItem>
                              <MenuItem value="inr">ID</MenuItem>
                            </FormInputSelect>
                          </div>
                        </div>
                      </div>
                    </NavbarLowerList>
                  </NavbarLower>
                </NavbarList>
              </>
            )}

            <>
              <Menu
                id="menu-appbar"
                elevation={0}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                // anchorPosition={{
                //   top: 300,
                // }}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                {token ? (
                  <MenuItem>
                    <NavbarMobieLinkList>
                      <Button
                        color="#000"
                        bg="#FFF"
                        br="2px"
                        pd=".2rem 1rem"
                        label={t("MY ACCOUNT")}
                        size="12px"
                        margin="0 0 .4rem 0"
                        href="/dashboard"
                      />
                      <Button
                        color="#000"
                        bg="#FFF"
                        br="2px"
                        pd=".2rem 1rem"
                        label={t("LOGOUT")}
                        size="12px"
                        onClick={() => handleLogout()}
                      />
                    </NavbarMobieLinkList>
                  </MenuItem>
                ) : (
                  <MenuItem>
                    <NavbarMobieLinkList>
                      <Button
                        color="#000"
                        bg="#FFF"
                        br="2px"
                        pd=".2rem 1rem"
                        label="REGISTER"
                        size="12px"
                        margin="0 0 .4rem 0"
                        href="/register"
                        // onClick={() => handleOpenRegister()}
                      />
                      <Button
                        color="#000"
                        bg="#FFF"
                        br="2px"
                        pd=".2rem 1rem"
                        label="LOGIN"
                        size="12px"
                        href="/login"
                        // onClick={() => handleOpenLogin()}
                      />
                    </NavbarMobieLinkList>
                  </MenuItem>
                )}
              </Menu>
            </>
          </Toolbar>
        </AppBar>
      </NavbarContainer>
    </>
  );
};

export default NavbarLefted;
