import { makeStyles } from "@mui/styles";
import styled from "styled-components";

export const config = {
  root: {},
};

export const CardContainer = styled.div`
  position: relative;
  background: ${({ bg }) => bg};
  border: ${({ border }) => border};
  border-radius: ${(props) => (props.br ? props.br : "4px")};
  box-shadow: ${(props) =>
    props.shadow ? "0px 0px 9px 3px rgba(0,0,0,0.1)" : props.shadow};
  padding: ${(props) => (props.pd ? props.pd : "1rem")};
  display: block;
  cursor: pointer;
`;

export default makeStyles(config);
