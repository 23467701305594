import React, { useState } from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import reward001 from "../../assets/images/reward001.png";
import { useTranslation } from "react-i18next";
import { claim_voucher, get_voucher_info } from "../../API/API";
import toast from "react-simple-toasts";
import useCart from "../../hooks/useCart";

export default function Membership() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  // PARAM
  // let { voucher_id } = useParams();

  const { t } = useTranslation();
  const { addProduct, getCart } = useCart();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // VOUCHER DETAIL
  const [voucherData, setVoucherData] = React.useState({});

  const getVoucherData = async () => {
    var voucher_id = params.voucher_id;
    var res = await get_voucher_info({
      voucher_id: voucher_id,
    });
    if (res.status) {
      console.log(res.data);
      setVoucherData(res.data);
    }
  };

  React.useEffect(() => {
    getVoucherData();
    const intervalId = setInterval(getVoucherData, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // HANDLE CLAIM VOUCHER

  const [isLoading, setIsLoading] = useState(false);
  const [showVoucherButton, setShowVoucherButton] = useState(true);

  const handleUseProductVoucher = async () => {
    var item = voucherData.item;

    var data = {
      name: {
        en: item.name,
        cn: item.name_cn,
        bm: item.name_bm,
        inr: item.name_inr
      },
      price: item.price,
      quantity: 1,
      product_id: item.item_id,
    };

    addProduct(data);
    navigate("/payment");

  }
  const handleClaimVoucher = async () => {
    if (isLoading) {
      return;
    }
    setShowVoucherButton(false);
    var res = await claim_voucher({
      voucher_id: params.voucher_id,
    });

    if (res.status) {
      toast("Voucher claimed !");
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setShowVoucherButton(true);
    }
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <Layout>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3}>
              <div className={classes.theNavList}>
                <a className={clsx(classes.theNavTab)} href="/dashboard">
                  {t("Dashboard")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/profile">
                  {t("My Profile")}
                </a>
                <a
                  className={clsx(classes.theNavTab, classes.theActive)}
                  href="/membership"
                >
                  {t("My Membership")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/order">
                  {t("My Orders")}
                </a>
                {/* <a className={clsx(classes.theNavTab)} href="/address">
                  {t("Address Book")}
                </a> */}
                <a className={clsx(classes.theNavTab)} href="/referral">
                  {t("My Referral")}
                </a>
                <a className={clsx(classes.theNavTab)} href="/setting">
                  {t("Setting")}
                </a>
                <a
                  className={clsx(classes.theNavTab)}
                  onClick={() => handleLogout()}
                >
                  {t("Logout")}
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Card shadow pd="0">
                <div className={classes.theHead}>
                  <Paragraph color="#FFF">{voucherData.name}</Paragraph>
                </div>
                <div className={classes.theBody}>
                  <div className={classes.theIMG2}>
                    <img src={voucherData.image} />
                  </div>
                  <br />
                  <div
                    style={{ whiteSpace: "break-spaces" }}
                    dangerouslySetInnerHTML={{
                      __html: voucherData.description,
                    }}
                  ></div>
                  <br />
                  <br />

                  {new Date(voucherData.expiry) > new Date() ? (
                    <>
                      {voucherData.user_claim == false ? (
                        <>
                          {showVoucherButton && (
                            <Button
                              color="#FFF"
                              bg="#4FCEA1"
                              label={t("claim_voucher_button", {
                                points: voucherData.points,
                              })}
                              size="14px"
                              br="4px"
                              pd=".6rem 1rem"
                              onClick={() => handleClaimVoucher()}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {voucherData.voucher_type == '1' ? (
                            <div
                              style={{
                                textAlign: "center",
                                borderTop: "1px solid #f1f1f1",
                              }}
                            >
                              <img
                                src={
                                  "https://chart.googleapis.com/chart?chs=250x250&cht=qr&chl=" +
                                  voucherData.redeem
                                }
                              />
                              <p>
                                {t(
                                  "Show this code to the shop staff to claim your voucher"
                                )}
                              </p>
                            </div>
                          ) : (
                            <Button
                              color="#FFF"
                              bg="#4FCEA1"
                              label={t("Use Voucher")}
                              size="14px"
                              br="4px"
                              pd=".6rem 1rem"
                              onClick={() => handleUseProductVoucher()}
                            />

                          )}

                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Card>
              <br />
              <br />
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
