import React, { useState } from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import FormInputNoLabel from "../../components/TextField/FormInputNoLabel/FormInputNoLabel";
import FormInputSelect from "../../components/TextField/FormInputSelect/FormInputSelect";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
} from "@mui/material";
import { Close, Mail, Phone, Room } from "@mui/icons-material";
import "./styles.css";
import useCart from "../../hooks/useCart";
import prod001 from "../../assets/images/prod001.png";
import { TextInput } from "../../components/TextField/FormInput/styles";
import {
  checkout,
  get_last_purchase,
  get_profile,
  validate_cart,
} from "../../API/API";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Loader from "react-js-loader";

export default function Payment() {
  const classes = useStyles();

  // CART
  const { getCart, setCartFunction, removeProduct, getTotalCart } = useCart();
  const { t, i18n } = useTranslation();
  const getcart = JSON.stringify(getCart());
  const [token, setToken] = useState(window.localStorage.getItem("token"));
  const [form, setForm] = React.useState({
    name: "",
    // email: "",
    contact: "",
    address: "",
    postcode: "",
    city: "",
    state: "",
    country: "Malaysia",
  });

  const [calculatedTotal, setCalculatedTotal] = React.useState(null);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [cart, setCart] = React.useState(getCart);

  const [stateOpt, setStateOpt] = React.useState([
    "Johor",
    "Kedah",
    "Kelantan",
    "Malacca",
    "Negeri Sembilan",
    "Pahang",
    "Penang",
    "Perak",
    "Perlis",
    "Sabah",
    "Sarawak",
    "Selangor",
    "Terengganu",
    "Kuala Lumpur",
    "Labuan",
    "Putrajaya",
  ]);

  const productCartList = getcart.replace(/\\/g, "");

  React.useEffect(() => {
    setCart(getCart());
    // validateCart(getCart());
    get_data();
  }, []);

  // CALCULATION
  const [shippingFee, setShippingFee] = React.useState(0);

  const [promoCodePrice, setPromoCodePrice] = React.useState(0);

  // CHOOSE SHIPPING ADDRESS

  const [chooseShippingAddress, setChooseShippingAddress] = React.useState(0);
  const handleChooseShippingAddress = (customer_address_id) => {
    setChooseShippingAddress(customer_address_id);
    console.log("SHIPPING ADDRESS", customer_address_id);
  };

  // OPEN MODAL
  const [openModal, setOpenModal] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  // CHECK DEFAULT ADDRESS

  const [checked, setChecked] = React.useState(false);
  const [defaultAddress, setDefaultAddress] = React.useState("");
  const handleCheckDefault = (event) => {
    setChecked(event.target.checked);

    if (event.target.checked == true) {
      setDefaultAddress("1");
    }

    if (event.target.checked == false) {
      setDefaultAddress("0");
    }
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    validateCart(getCart());
  }, [form.state, form.country]);

  React.useEffect(() => {
    calculateShipping();
  }, [form.country, form.state]);
  const calculateShipping = () => {
    var _shippingFee = 0;
    var _additonalPerBox = 0;
    if (form.country == "Malaysia") {
      if (
        form.state == "Sabah" ||
        form.state == "Sarawak" ||
        form.state == "Labuan"
      ) {
        _shippingFee = 20;
        _additonalPerBox = 5.5;
      }
    } else {
      _shippingFee = 91;
      _additonalPerBox = 75;
    }

    if (_additonalPerBox > 0) {
      for (var i = 0; i < cart.length; i++) {
        _shippingFee += (cart[i].quantity - 1) * _additonalPerBox;
      }
    }
    setShippingFee(_shippingFee);
  };

  const [userData, setUserData] = useState(null);
  const get_data = async () => {
    var res_user = await get_profile();
    if (res_user.status) {
      setUserData(res_user.data);

      // console.log(res_user.data.email)
      var _newForm = {
        ...form,
        name: res_user.data.name,
        email: res_user.data.email,
        contact: res_user.data.contact,
        dob: res_user.data.DOB,
      };
      setForm(_newForm);

      var res_address = await get_last_purchase();
      if (res_address.status) {
        var _address = res_address.data;
        if (res_address.data.length) {
          _newForm = {
            ..._newForm,
            address: res_address.data[0].address,
            postcode: res_address.data[0].postcode,
            city: res_address.data[0].city,
            state: res_address.data[0].state,
            country: res_address.data[0].country,
          };

          setForm(_newForm);
        }
      } else {
        setForm(_newForm);
      }
    }
  };

  const validateCart = async (cart) => {
    var _data = {
      order_items: [],
      state: form.state,
      country: form.country,
    };
    cart.map((r) => {
      _data.order_items.push({
        item_id: r.product_id,
        quantity: r.quantity,
      });
    });
    _data["order_items"] = JSON.stringify(_data.order_items);
    var res = await validate_cart(_data);
    if (res.status) {
      setCalculatedTotal({
        subtotal: res.data.subtotal,
        total: res.data.total,
        shipping: res.data.shipping,
        discount: res.data.discount,
      });
    }
  };
  const handleCheckout = async (payment_type) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    if (!userData) {
      var fields = [
        "name",
        "dob",
        "email",
        "contact",
        "address",
        "postcode",
        "city",
        "state",
        "country",
      ];
      for (var i = 0; i < fields.length; i++) {
        if (form[fields[i]] == "") {
          alert("Please enter all fields");
          setIsLoading(false);
          return;
        }
      }
      // console.log(JSON.stringify(form));
      var _data = {
        ...form,
        payment_type: payment_type,
        order_items: [],
      };

      cart.map((r) => {
        _data.order_items.push({
          item_id: r.product_id,
          quantity: r.quantity,
        });
      });
      _data.order_items = JSON.stringify(_data.order_items);
      console.log(_data);
      var response = await checkout(_data);
      if (response) {
        if (response.status) {

          if (payment_type == 2) {
            alert("Purchase Complete");
            setIsLoading(false);
            window.localStorage.setItem("token", response.data.token);

            window.location.href = response.data.eghl;

          } else if (payment_type == 1) {
            alert("Purchase Complete");
            setIsLoading(false);
            window.localStorage.setItem("token", response.data.token);
            navigate("/order/details/" + response.data.orders_id);
          }

        } else {
          alert(response.message);
          setIsLoading(false);
        }
      }

      setIsLoading(false);
    } else {
      var fields = [
        "name",
        "contact",
        "address",
        "postcode",
        "city",
        "state",
        "country",
      ];
      for (var i = 0; i < fields.length; i++) {
        if (form[fields[i]] == "") {
          alert("Please enter all fields");
          setIsLoading(false);
          return;
        }
      }

      // console.log(JSON.stringify(form));

      var _data = {
        ...form,
        payment_type: payment_type,
        order_items: [],
      };

      cart.map((r) => {
        _data.order_items.push({
          item_id: r.product_id,
          quantity: r.quantity,
        });
      });
      _data.order_items = JSON.stringify(_data.order_items);
      console.log(_data);
      var response = await checkout(_data);
      if (response) {
        if (response.status) {
          if (payment_type == 2) {
            // alert("Purchase Complete");
            setIsLoading(false);
            window.localStorage.setItem("token", response.data.token);

            window.location.href = (response.data.eghl);

          } else if (payment_type == 1) {
            alert("Purchase Complete");
            setIsLoading(false);
            window.localStorage.setItem("token", response.data.token);
            navigate("/order/details/" + response.data.orders_id);
          }

        } else {
          alert(response.message);
          setIsLoading(false);
        }
      }

      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <NavbarLefted />

      <Layout pd="4rem 0">
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              {(!token || token == undefined) && (
                <>
                  <h3>{t("Already Have an Account")} ? </h3>
                  <Button
                    label={t("Login Now")}
                    color="#FFF"
                    bg="#4FCEA1"
                    br="6px"
                    href="/login"
                  />
                  <br />
                  <div
                    style={{
                      background: "#d7d7d7",
                      width: "100%",
                      height: 1,
                    }}
                  />
                </>
              )}
              <h3>{t("Shipping Info")}</h3>
              <FormInput
                label={t("Name")}
                value={form.name}
                placeholder={t("Name")}
                name="name"
                onChange={handleChange}
              />
              {!userData && (
                <>
                  <FormInput
                    label={t("Date Of Birth")}
                    value={form.dob}
                    placeholder={t("Date Of Birth")}
                    name="dob"
                    type="date"
                    onChange={handleChange}
                  />
                  <FormInput
                    value={form.email}
                    label={t("Email")}
                    placeholder={t("Email")}
                    name="email"
                    onChange={handleChange}
                  />
                </>
              )}

              <FormInput
                value={form.contact}
                label={t("Contact Number")}
                placeholder={t("Contact Number")}
                name="contact"
                onChange={handleChange}
              />
              <Paragraph margin="0 0 .4rem 0">{t("Country")}</Paragraph>
              <FormInputSelect
                border="1px solid #CED4DA"
                value={form.country}
                onChange={handleChange}
                name="country"
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value={"Malaysia"}>Malaysia</MenuItem>
                <MenuItem value={"Singapore"}>Singapore</MenuItem>
              </FormInputSelect>
              <FormInput
                value={form.address}
                label={t("Address")}
                placeholder={t("Address")}
                name="address"
                onChange={handleChange}
              />
              <FormInput
                label={t("Postcode")}
                placeholder={t("Postcode")}
                value={form.postcode}
                name="postcode"
                onChange={handleChange}
              />

              <FormInput
                label={t("City")}
                value={form.city}
                placeholder={t("City")}
                name="city"
                onChange={handleChange}
              />

              {form.country == "Malaysia" ? (
                <>
                  <Paragraph margin="0 0 .4rem 0">{t("State")}</Paragraph>
                  <FormInputSelect
                    border="1px solid #CED4DA"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    value={form.state}
                    name="state"
                    MenuProps={{
                      disableScrollLock: true,
                    }}
                  >
                    {stateOpt.map((r) => (
                      <MenuItem value={r}>{r}</MenuItem>
                    ))}
                  </FormInputSelect>
                </>
              ) : (
                <FormInput
                  label={t("State")}
                  placeholder={t("State")}
                  name="state"
                  value={form.state}
                  onChange={handleChange}
                />
              )}

              {/* <br />
              <br />
              <label>{t("State")}</label>
              {form.country == "Malaysia" ? (
                <TextInput
                  name="state"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  style={{
                    width: "100%",
                    marginTop: 10,
                  }}
                  select
                >
                  {stateOpt.map((r) => (
                    <MenuItem value={r}>{r}</MenuItem>
                  ))}
                </TextInput>
              ) : (
                <TextInput
                  name="state"
                  onChange={handleChange}
                  style={{
                    width: "100%",
                    marginTop: 10,
                  }}
                ></TextInput>
              )} */}

              <br />
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              {cart != undefined && cart.length > 0
                ? cart.map((item, index) => (
                  <div className={classes.theFlex}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className={classes.theProdIMG}>
                        <img src={item.images} />
                      </div>
                      <div>
                        <Paragraph
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name[i18n.language]}
                        </Paragraph>
                        <Paragraph size="14px">{item.quantity} box</Paragraph>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "100%",
                      }}
                    >
                      <Paragraph style={{ textAlign: "right" }}>
                        MYR {item.price}
                      </Paragraph>
                      <Close
                        sx={{ color: "#F90000", marginLeft: "1rem" }}
                        onClick={() => removeProduct(index)}
                      />
                    </div>
                  </div>
                ))
                : null}
              {/* <div className={classes.theFlex}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div className={classes.theProdIMG}>
                    <img src={prod001} />
                  </div>
                  <div>
                    <Paragraph>Royal Bitter Gourd Petite</Paragraph>
                    <Paragraph size="14px">00 box</Paragraph>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <Paragraph style={{ textAlign: "right" }}>
                    MYR 000.00
                  </Paragraph>
                  <Close sx={{ color: "#F90000", marginLeft: "1rem" }} />
                </div>
              </div> */}
              {/* <FormInputNoLabel
                placeholder="Promo Code"
                bg="#FFF"
                br="4px"
                pd="14.5px 14px"
                InputProps={{
                  endAdornment: (
                    <Button
                      color="#FFF"
                      bg="#4FCEA1"
                      label="APPLLY"
                      size="14px"
                      br="4px"
                      pd=".4rem 2rem"
                    />
                  ),
                }}
              /> */}
              {calculatedTotal && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">{t("Subtotal")}</Paragraph>
                    <Paragraph bold="500" size="1.2rem">
                      MYR {calculatedTotal.subtotal}
                    </Paragraph>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                    }}
                  >
                    <Paragraph size="1.2rem">{t("Shipping Fee")}</Paragraph>
                    {calculatedTotal.shipping == "0.00" ? (
                      <Paragraph bold="500" size="1.2rem">
                        Free
                      </Paragraph>
                    ) : (
                      <Paragraph bold="500" size="1.2rem">
                        MYR {calculatedTotal.shipping}
                      </Paragraph>
                    )}
                  </div>
                  <Paragraph margin=".2rem 0 1rem 0" size="14px" style={{fontStyle: "italic"}}>West Malaysia Free Shipping</Paragraph>
                  {calculatedTotal.discount != "0.00" && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <Paragraph size="1.2rem">{t("Discount")}</Paragraph>
                      <Paragraph bold="500" size="1.2rem">
                        -MYR {calculatedTotal.discount}
                      </Paragraph>
                    </div>
                  )}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "1rem",
                      paddingTop: "1rem",
                      borderTop: "1px solid #d7d7d7",
                    }}
                  >
                    <Paragraph size="1.2rem">{t("Total")}</Paragraph>
                    <Paragraph color="#194234" bold="700" size="1.2rem">
                      MYR {calculatedTotal.total}
                    </Paragraph>
                  </div>
                  
                  {isLoading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "600px",
                      }}
                    >
                      <Loader
                        type="snipper-default"
                        bgColor={"#878787"}
                        size={50}
                      />
                    </div>
                  ) : (
                    <>
                      <Button
                        label={t("Pay Online")}
                        color="#FFF"
                        bg="#4FCEA1"
                        br="6px"
                        onClick={() => {
                          handleCheckout(2);
                        }}
                      />
                      <br />
                      <Button
                        label={t("Manual Bank In")}
                        color="#FFF"
                        bg="#a1a1a1"
                        br="6px"
                        onClick={() => {
                          handleCheckout(1);
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Layout>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        sx={{ width: "100%" }}
        scroll="body"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogContent>
          <div className={classes.theSection}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Close
                sx={{ color: "#353535", cursor: "pointer" }}
                onClick={() => setOpenModal(false)}
              />
            </div>

            <br />
            <Paragraph
              color="#194234"
              size="24px"
              bold="700"
              margin="0 0 2rem 0"
              center
            >
              ADD NEW ADDRESS
            </Paragraph>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <FormInput
                  label="Address Line 1"
                  placeholder="Address Line 1"
                />
                <FormInput
                  label="Address Line 2"
                  placeholder="Address Line 2"
                />
                <Paragraph margin="0 0 .4rem 0">State</Paragraph>
                <FormInputSelect
                //   value={chooseState}
                //   onChange={handleChooseState}
                >
                  <MenuItem value="" sx={{ fontWeight: "400" }}>
                    <em style={{ fontStyle: "italic" }}>
                      Please Choose a State
                    </em>
                  </MenuItem>
                  <MenuItem value="Johor">Johor</MenuItem>
                </FormInputSelect>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckDefault}
                      value={defaultAddress}
                      style={{ color: "#4FCEA1" }}
                    />
                  }
                  label={
                    <Paragraph color="#000">
                      Set As Default Billing Address
                    </Paragraph>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Paragraph margin="0 0 .4rem 0">Couuntry</Paragraph>
                <FormInputSelect
                //   value={chooseCouuntry}
                //   onChange={handleChooseCountry}
                >
                  <MenuItem value="" sx={{ fontWeight: "400" }}>
                    <em style={{ fontStyle: "italic" }}>
                      Please Choose a Country
                    </em>
                  </MenuItem>
                  <MenuItem value="Malaysia">Malaysia</MenuItem>
                </FormInputSelect>
                <FormInput label="City" placeholder="City" />
                <FormInput
                  label="Zip / Postal Code"
                  placeholder="Zip / Postal Code"
                />
              </Grid>
            </Grid>

            <br />
            <div className={classes.theBtnSizing}>
              <Button
                label="SUBMIT"
                color="#FFF"
                bg="#4FCEA1"
                br="6px"
              //   onClick={() => handleAddAddress()}
              />
            </div>
            <br />
          </div>
          <br />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
