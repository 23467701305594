import React from "react";
import classNames from "classnames";
import useStyles, { FormGroup, FormInputSelectContainer } from "./styles";
import { Select } from "@mui/material";

const FormInputSelect = ({
  textLabelColor,
  textLabelSize,
  textLabelBold,
  textLabelMargin,
  label,
  bg,
  br,
  mb,
  bold,
  center,
  border,
  textColor,
  textPlaceholderColor,
  size,
  required,
  children,
  className,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormGroup mb={mb}>
      <FormInputSelectContainer
        bg={bg}
        br={br}
        bold={bold}
        center={center}
        border={border}
        textColor={textColor}
        textPlaceholderColor={textPlaceholderColor}
        size={size}
        fullWidth
        // sx={{
        //   "& .MuiOutlinedInput-root": { borderRadius: ".25rem" },
        // }}
      >
        <Select
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          className={classNames(classes, className)}
          sx={{
            width: "100%",
          }}
          {...props}
        >
          {children}
        </Select>
      </FormInputSelectContainer>
    </FormGroup>
  );
};

export default FormInputSelect;
