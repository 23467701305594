import React from "react";
import classNames from "classnames";
import FormLabel from "../../Typography/FormLabel";
import useStyles, { FormGroup, Span, TextInput } from "./styles";

const FormInput = ({
  textLabelColor,
  textLabelSize,
  textLabelBold,
  textLabelMargin,
  label,
  bg,
  br,
  pd,
  bold,
  center,
  border,
  textcolor,
  placeholdercolor,
  size,
  required,
  className,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormGroup>
      <div className={classes.root}>
        <FormLabel
          textLabelSize={textLabelSize}
          textLabelBold={textLabelBold}
          textLabelMargin={textLabelMargin}
          textLabelColor={textLabelColor}
        >
          {label}
        </FormLabel>
        <Span
          style={{ color: "#F90000", marginLeft: "4px" }}
          required={required}
        >
          *
        </Span>
      </div>
      <TextInput
        bg={bg}
        br={br}
        pd={pd}
        bold={bold}
        center={center}
        border={border}
        textcolor={textcolor}
        placeholdercolor={placeholdercolor}
        size={size}
        fullWidth
        className={classNames(classes, className)}
        sx={{
          display: "block",
        //   "& .MuiOutlinedInput-root": { borderRadius: ".25rem" },
        }}
        {...props}
      >
        {children}
      </TextInput>
    </FormGroup>
  );
};

export default FormInput;
