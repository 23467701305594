import React from "react";
import useStyles from "./styles";
import Button from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import { Box, Grid } from "@mui/material";
import { Facebook, Mail, Phone, Room } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { feedback } from "../../API/API";

export default function Feedback() {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  // CONTACT
  const [feedbackData, setFeedbackData] = React.useState({
    name: "",
    email: "",
    contact: "",
    message: "",
  });
  const handleChange = (e) => {
    setFeedbackData({
      ...feedbackData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = () => {
    if (
      !feedbackData.email ||
      !feedbackData.name ||
      !feedbackData.contact ||
      !feedbackData.message
    ) {
      alert("All Field Required");
      return;
    }

    feedback(feedbackData).then((json) => {
      if (json.status) {
        alert("Submit Successfully");
        window.location.reload();
      } else {
        alert(json.message);
      }
    });
  };

  return (
    <React.Fragment>
      <NavbarLefted />
      <div
        style={{
          // background: "#194234",
          height: "250px",
          display: "flex",
          alignItems: "center",
        }}
        className={classes.thePattern}
      >
        <Layout>
          <Paragraph
            color="#FFF"
            size="32px"
            bold="700"
            margin="0 0 1rem 0"
            center
          >
            {t("Feedback")}
          </Paragraph>
        </Layout>
      </div>
      <Layout>
        <Box>
          {/* <Paragraph
            color="#194234"
            bold="700"
            size="32px"
            margin="0 0 4rem 0"
            center
          >
            {t("About")} We Healthy Management Sdn. Bhd.
          </Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">{t("desp001")}</Paragraph>
          <Paragraph size="20px" margin="0 0 1,5rem 0">{t("desp002")}</Paragraph>
          <Paragraph size="20px" margin="0 0 1.5rem 0">{t("desp003")}</Paragraph> */}
          <FormInput
            label={t("Name")}
            placeholder={t("Name")}
            name="name"
            onChange={handleChange}
          />
          <FormInput
            label={t("Message")}
            placeholder={t("Message")}
            rows="3"
            multiline
            name="message"
            onChange={handleChange}
          />
          <br />
          <Button
            color="#FFF"
            bg="#4FCEA1"
            label={t("SEND")}
            size="14px"
            br="4px"
            pd=".6rem 1rem"
            onClick={() => handleSubmit()}
          />
        </Box>
      </Layout>
      <Footer />
    </React.Fragment>
  );
}
