import styled from "styled-components";
import { makeStyles } from "@mui/styles";
import { mq } from "../../styles/responsive";

const config = {
  theScrollAddress: {
    position: "relative",
    height: "220px",
    overflow: "scroll",
  },
  theProdIMG: {
    position: "relative",
    width: "20%",
    marginRight: "1rem",

    "& img": {
      width: "100%",
    },
  },
  theFlex: {
    display: "flex",
    justifyContent: "space-bewtween",
    alignItems: "center",
    padding: "1rem 0",
    borderBottom: "1px solid #d7d7d7",
  },
  theBtnSizing: {
    position: "relative",
    width: "100%",
    margin: "0 auto",

    ...mq("sm", {
      width: "30%",
    }),

    ...mq("md", {
      width: "40%",
    }),
  },
};

export default makeStyles(config);
