import React from "react";
import useStyles from "./styles";
import clsx from "clsx";
import Button from "../../components/Button/Button";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import FormInput from "../../components/TextField/FormInput/FromInput";
import Layout from "../../components/Layout/Layout";
import NavbarLefted from "../../components/Navbar/NavbarLefted/NavbarLefted";
import Paragraph from "../../components/Typography/Paragraph";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Mail, Phone, Room } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get_orders, get_profile } from "../../API/API";
import crown001 from "../../assets/images/crown001.png";

export default function Contact() {
  const classes = useStyles();
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  // LOGOUT
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    navigate("/");
  };

  // PROFILE DATA
  const [orders, setOrders] = React.useState([]);
  const [profileData, setProfileData] = React.useState(null);
  const getProfileData = async () => {
    var res = await get_profile();
    if (res.status) {
      // setProfileData(res.data);
      setProfileData({
        ...res.data,
        plan: {
          en: res.data.plan,
          cn: res.data.plan_cn,
          my: res.data.plan_bm,
          inr: res.data.plan_inr,
        },
      });
    } else {
      alert("Please log in again");
      handleLogout();
    }
    // profile({ token: token }).then((json) => {
    //   setProfileData(json.data);
    // });
  };

  // ORDER LIST
  const [orderList, setOrderList] = React.useState([]);

  const getOrderList = async () => {
    var res = await get_orders();
    if (res.status) {
      setOrderList(res.data);
    }
  };

  React.useEffect(() => {
    getProfileData();
    getOrderList();
  }, []);

  return (
    <React.Fragment>
      <NavbarLefted />
      {profileData && (
        <Layout>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3}>
                <div className={classes.theNavList}>
                  <a
                    className={clsx(classes.theNavTab, classes.theActive)}
                    href="/dashboard"
                  >
                    {t("Dashboard")}
                  </a>
                  <a className={clsx(classes.theNavTab)} href="/profile">
                    {t("My Profile")}
                  </a>
                  {profileData && (
                    <>
                      {profileData.is_confirmed_member == 1 && (
                        <a
                          className={clsx(classes.theNavTab)}
                          href="/membership"
                        >
                          {t("My Membership")}
                        </a>
                      )}
                    </>
                  )}

                  <a className={clsx(classes.theNavTab)} href="/order">
                    {t("My Orders")}
                  </a>

                  {profileData && (
                    <>
                      {profileData.is_confirmed_member == 1 && (
                        <a className={clsx(classes.theNavTab)} href="/referral">
                          {t("My Referral")}
                        </a>
                      )}
                    </>
                  )}

                  <a className={clsx(classes.theNavTab)} href="/setting">
                    {t("Setting")}
                  </a>
                  <a
                    className={clsx(classes.theNavTab)}
                    onClick={() => handleLogout()}
                  >
                    {t("Logout")}
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <Paragraph
                  color="#194234"
                  size="28px"
                  bold="700"
                  margin="0 0 1rem 0"
                >
                  {t("Welcome")} <br /> {profileData.name}
                </Paragraph>

                <Card shadow pd="0">
                  <div className={classes.theHead}>
                    <Paragraph color="#FFF">{t("YOUR PROFILE")}</Paragraph>
                  </div>
                  <div className={classes.theBody}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Paragraph color="#194234" size="18px" bold="600">
                        {profileData.name}
                      </Paragraph>
                      <a
                        style={{
                          color: "#4FCEA1",
                          fontSize: "14px",
                          textDecoration: "underline",
                        }}
                        href="/profile"
                      >
                        {t("EDIT PROFILE")}
                      </a>
                    </Box>
                    <br />
                    <br />

                    <Paragraph color="#707070" margin="0 0 .4rem 0">
                      {profileData.contact}
                    </Paragraph>
                    <Paragraph color="#707070" margin="0 0 .4rem 0">
                      {profileData.email}
                    </Paragraph>
                    {profileData.plan[i18n.language] && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className={classes.theCrown}>
                          <img src={crown001} />
                        </div>
                        <Paragraph
                          margin="0 0 0 14px"
                          bold="700"
                          color="#194234"
                        >
                          {profileData.plan[i18n.language]}
                        </Paragraph>
                      </Box>
                    )}
                  </div>
                </Card>
                <br />
                <br />
                <Card shadow pd="0">
                  <div className={classes.theHead}>
                    <Paragraph color="#FFF">{t("MY ORDERS")}</Paragraph>
                  </div>
                  <div className={classes.theBody}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{t("Order Date")}</TableCell>
                            <TableCell>{t("Amount")}</TableCell>
                            <TableCell>{t("Status")}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderList.map((r) => (
                            <TableRow
                              onClick={() => {
                                navigate("/order/details/" + r.orders_id);
                              }}
                            >
                              <TableCell>{r.orders_id}</TableCell>
                              <TableCell>{r.created_date}</TableCell>
                              <TableCell>MYR {r.total_amount}</TableCell>
                              <TableCell>{r.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <br />
                    <div className={classes.theBtnSizing}>
                      <Button
                        color="#FFF"
                        bg="#4FCEA1"
                        label={t("VIEW ALL ORDERS")}
                        size="14px"
                        br="4px"
                        pd=".6rem 1rem"
                        href="/order"
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Layout>
      )}

      <Footer />
    </React.Fragment>
  );
}
